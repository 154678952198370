// environment variables
export const baseUrl = process.env.BASE_URL;
export const apiHost = process.env.API_HOST;
export const subsbaseId = process.env.SUBSBASE_ID;
export const firebaseApiKey = process.env.FIREBASE_API_KEY;
export const firebaseProjectId = process.env.FIREBASE_PROJECT_ID;
export const firebaseStorageBucket = process.env.FIREBASE_STORAGE_BUCKET;
export const firebaseMessagingSenderId = process.env.FIREBASE_MESSAGING_SENDER_ID;
export const firebaseAppId = process.env.FIREBASE_APP_ID;
export const firebaseAuthDomain = process.env.FIREBASE_AUTH_DOMAIN;
export const firebaseVapidKey = process.env.FIREBASE_VAPID_KEY;

export const socialAuthProviders = ["google_oauth2", "facebook", "apple"];

export const imageLimit = 100;

export const weightUnits = {
  kg: 1,
  g: 1 / 1000,
};
export const LANGUAGE_OPTIONS = [
  { label: "English", value: "en" },
  { label: "Arabic", value: "ar" },
];

export const PRICE_DECIMALS = [
  { label: "999", value: 0 },
  { label: "999.9", value: 1 },
  { label: "999.99", value: 2 },
  { label: "999.999", value: 3 },
];

export const editorButtonsMap = {
  bold: "b".charCodeAt(0),
  italic: "i".charCodeAt(0),
  underline: "u".charCodeAt(0),
};

export const productModules = [
  "media",
  "basicInfo",
  "pricing",
  "inventory",
  "variants",
  "categorization",
  "shipping",
  "extras",
  "status",
];

export const storeMilestones = [
  {
    name: "hasProducts",
    icon: "icon-zam-abstract-product",
    linkTo: "all_products",
  },
  { name: "hasCourier", icon: "icon-zam-truck", linkTo: "settings" },
  { name: "hasCustomDomain", icon: "icon-zam-www-globe", linkTo: "settings" },
  {
    name: "hasPaymentMethod",
    icon: "icon-zam-credit-card",
    linkTo: "settings",
  },
];

export const billingFrequencySequences = {
  monthly: 1,
  quarterly: 2,
  semi: 3,
  annually: 4,
  biannually: 5,
};

export const planSavingsMap = {
  monthly: null,
  quarterly: 10,
  semi: 15,
  annually: 25,
  biannually: 40,
};

export const billingFrequencyMonths = {
  monthly: 1,
  quarterly: 3,
  semi: 6,
  annually: 12,
  biannually: 24,
};
