export default function () {
  return {
    metadata: {
      loading: false,
      error: null,
    },
    currentReviews: {
      loading: false,
      error: null,
      reviewList: [],
      metadata: {
        totalCount: 0,
      },
    },
  };
}
