export function updateReviewsList(state, response) {
  state.currentReviews.reviewList = response;
}

export function updateReviewStatus(state, response) {
  const review = state.currentReviews.reviewList.findIndex((item) => item.id === response.id);
  state.currentReviews.reviewList[review] = response;
}

export function updateAllReviewsStatus(state, response) {
  state.currentReviews.reviewList = state.currentReviews.reviewList.map((review) => {
    return {
      ...review,
      isHidden: response,
    };
  });
}

export function updateReviewsLoading(state, isLoading) {
  state.currentReviews = {
    ...state.currentReviews,
    loading: isLoading,
  };
}