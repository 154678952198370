import partner from "./partner";

export default {
  general: {
    welcome_zammit: "Welcome to Zammit",
    welcome_back: "Welcome back",
    welcome: "Welcome",
    step: "Step",
    next: "Next",
    skip: "Skip",
    save: "Save",
    cancel: "Cancel",
    remove: "Remove",
    discard: "Discard",
    or: "or",
    verify: "Verify",
    previous: "Previous",
    language: "Language",
    store_language: "Default store language",
    generic_error: "Oops! Something went wrong. Please try again.",
    no_internet: "You are currently offline.",
    refresh: "Refresh",
    internet_back: "Your internet connection was restored",
    help: "Help",
    privacy: "Privacy",
    terms: "Terms",
    optional: "optional",
    value: "Value",
    price: "Price",
    add_more: "Add more",
    items: "item(s)",
    available: "Available",
    preview: "Preview",
    edit: "Edit",
    back: "Back",
    new: "New",
    soon: "Soon",
    add: "Add",
    na: "N/A",
    etc: "Etc.",
    unauthorized_access: "You don't have permissions to access this page",
  },
  language_switcher: {
    desktop: "تغيير اللغة إلى العربية",
    mobile: "اللغة العربية",
    lang: "عربي",
  },
  headers: {
    home: "Home",
    orders: "Orders",
    products: "Products",
    collections: "Collections",
    inventory: "Inventory",
    pricing: "Pricing",
    discounts: "Discounts",
    all_customers: "All Customers",
    customer_details: "Customer Details",
    create_order: "Create new order",
    staff_management: "Staff Management",
    staff_profile: "Staff Management",
    add_product: "Add Product",
    all_products: "All Products",
    add_collection: "Add Collection",
    add_discount: "New Promo Code",
    add_customer: "Add customer",
    add_staff: "Add staff",
    product_list: "Product List",
    variants: "Variants",
    stock: "Stock",
    new_variant: "Add new variant",
    new_collection: "Add new collection",
    trial_ended: "Free Trial Ended",
    pick_plan: "Pick your plan",
    renew_plan: "Plan Expired",
    upgrade_plan: "Upgrade your plan",
    subscribe: "Subscribe",
    add_address: "Add your billing address",
    navigation: "Navigation",
    plan_details: "Plan Details",
    analytics: "Analytics",
    online_analytics: "Online analytics",
    zam_pos_analytics: "ZamPOS analytics",
    partners: "Partners",
    instagram_import: "Instagram Import",
    payment_successful: "Payment Successful",
    coming_soon: "Coming Soon",
    send_sms: "Send SMS",
    borrow_and_grow: "Borrow & Grow",
    paymob_self_onboarding: "Receive Payments by Paymob",
    "e-tax": "E-Tax",
    payment: "Payment",
    integrations: "Integrations",
    shipping: "Shipping",
    company: "Company",
    policies: "Policies",
    social_media_links: "Social Media Links",
    shop_customization: "Shop Customization",
    advanced_settings: "Advanced Settings",
    settings: "Settings",
    general_settings: "General Settings",
    checkout_settings: "Checkout Settings",
    users_and_permissions: "Users and Permissions",
    related_products: "Related Products",
    products_reviews: "Product Reviews",
    go_to_products: "Go to Products",
    updates: "Updates",
  },
  auth: {
    first_name: "First name",
    last_name: "Last name",
    full_name: "Full name",
    contact_phone: "Contact phone",
    email: "Email address",
    signup_terms: {
      1: "By signing up, I agree to Zammit's",
      2: "terms & conditions",
    },
    finish_signup: "Finish signing up",
    redirect: "Login Successful. You will be redirected to Zammit.",
    already_have_account: "Already have a store?",
    ask_phone: "What is your phone number?",
    ask_email: "What is your email address?",
    continue_email: "Continue with email",
    continue_phone: "Continue with phone number",
    continue_google_oauth2: "Continue with Google",
    continue_facebook: "Continue with Facebook",
    continue_apple: "Continue with Apple",
    receive_sms_verification_hint: "You'll receive an SMS with a verification code",
    phone_error_length: "Number should be at least 11 digits long",
    phone_error_start: "Number should start with 1",
    phone_error_start_and_length: "Number should start with 1 and be at least 11 digits long",
    enter_otp: "Enter the code we sent to",
    confirm_number: "Confirm your number",
    got_no_code: "Didn't get a code?",
    resend_code: "Resend code",
    wait: "Wait for 30 secs",
    create_your_password: "Create your password",
    create_password: "Create password",
    enter_password: "Enter password",
    enter_phone: "Please enter your phone number",
    email_error: "Not a valid email",
    trouble_sign_in: "Having troubles signing in?",
    send_us_email: "Send us an email",
    show_password: "Show password",
    password_length: "Password should be at least 8 characters",
    password_special_char: "Password should include at least one number or symbol",
    sign_up: "Sign Up",
    sign_in: "Sign In",
    login: "Login",
    signin_continue: "Sign in to continue",
    not_you: "Not you?",
    use_another_account: "Use another account",
    forgot_password_text: "I forgot my password",
    forgot_password: {
      title: "Forgot my password",
      enter_email: "Enter your email",
      enter_email_subtitle:
        "Enter the email address associated with your account, and we’ll email you a link to reset your password.",
      email: "Email address",
      reset_link: "Send reset link",
      success: "Email sent successfully",
      success_subtitle: "Please check your inbox. A link to reset your password has been sent to {email}",
      update_password: "Update password",
      create_password: "Create password",
      reenter_password: "Re-enter your password",
      update: "Update",
      token_missing_error: "Please open the link sent to your email again",
    },
  },
  store_creation: {
    store_info_header: "Amazing store name,",
    store_info_subheader: "Your future self will thank you for taking the right decision",
    store_info_1_title: "Tell us more about your business",
    store_info_1_industry_question: "What's your industry?",
    store_info_1_subindustry_question: "What is your specialty?",
    store_info_2_title: "Add your business address.",
    dina_dash_founder: "Dina Dash, Founder",
    dina_dash_quote:
      '"I really enjoyed working with Zammit, the website is very easy to use and work around. The team is very helpful and super attentive to detail. I would recommend start ups and business to sign up and make their life easier."',
    store_creation_title: "Enter your company & contact info",
    store_creation_subtitle:
      "Say goodbye to managing inventory & online payments through Excel sheets! Your online store is minutes away.",
    store_name: "Store Name",
    store_url: "Store URL",
    store_url_hint: "Write your store name in English without spaces",
    contact_name: "Contact Name",
    contact_email: "Contact Email (Optional)",
    create_my_store: "Create my website",
    launch: "Let's get started",
    loading: "Hold on, we are creating your {title} store...",
    welcome_onboard_1: "{store} store is created successfully.",
    welcome_onboard_2: "Welcome onboard, {name}",
    go_my_store: "Go to my store",
  },
  explore_plans: {
    renew: {
      title: "Oops! Your plan has expired",
      desc: "Your Zammit plan has ended.",
    },
    upgrade: {
      title: "Ready to do more with Zammit!",
      desc: "There’s a lot of awesome features waiting for you. Upgrade your plan now to enjoy those new features.",
    },
    select_plan_sentence: "But good news is we have awesome plans to select from.",
    simple_plan: "Simple plans. Simple prices. Only pay for what you really need.",
    customer_service: "All plans come with award-winning 24/7 customer support.",
    change_plans: "Change or cancel your plan at any time.",
    btn: "Explore available plans",
    support: "Need support choosing your plan?",
    send_email: "Send us an email",
  },
  cash_on_delivery: {
    thank_you: "Thank you for choosing Zammit.",
    courier: "The courier will contact you to schedule a pickup time.",
  },
  payment_successful: {
    title: "Payment Successful",
    thank_you: "Thank you for choosing Zammit, Explore more now.",
  },
  gift_order: {
    gift_alert: "This order is a gift",
  },
  errors: {
    title: {
      multiple: "There is {num} errors detected",
      single: "There is an error detected",
    },
    required: "This field is required!",
    auth: {
      check_email: "Could not access our servers, please hold on",
      enter_otp: "Wrong OTP",
      request_otp: "Could not send an OTP, you can try again",
      wrong_password: "Wrong email or password",
      sign_up: "Could not create your account",
      edit_info: "Could not edit your account",
      forgot_password: "Could not send an email",
      update_password: "Could not update password",
      re_login: "You have to login to perform this action",
    },
    orders: {
      get_orders: "Fetching orders failed",
      get_draft_orders: "Fetching abandoned carts failed",
      get_order: "Could not fetch the data for this order",
      send_to_courier: "Send to courier failed",
      get_voo_couriers: "Fetching VOO couriers failed",
      update_order: "Could not update this order",
      cancel_order: "Canceling this order failed",
      delete_order: "Deleting this draft order failed",
      export: "Could not export orders",
      airway_bill: "Could not fetch the airway bill for this order",
      e_invoice: "Could not fetch the e-invoice for this order",
      create_draft_order: "Creating a draft order failed",
      save_order: "activating this order failed",
      customer_address: "This customer does not have an address",
      bulk_update_orders: "Could not bulk update the orders",
      bulk_airway_bill: "Could not bulk fetch the airway bill",
      update_allow_customer_to_open: "Could not update allow customer to open",
    },
    products: {
      add_product: "Could not add product",
      metadata: "Fetching metadata failed",
      edit_product: "Could not edit this product",
      duplicate: "Could not duplicate product",
      delete_product: "Deleting this product failed",
      get_product: "Could not fetch the data for this product",
      export: "Could not export products",
      import: "Could not import products",
      instagram: "Could not fetch data from Instagram",
      image: "Image limit exceeded!",
      image_size: " Image should have a maximum size of 10 megabyte!",
      get_products: "Fetching products failed",
      delete_products: "Deleting selected product(s) failed",
      update_review: "Could not hide review",
      get_reviews: "Could not fetch reviews",
    },
    discounts: {
      add_discount: "Could not add discount",
      metadata: "Fetching metadata failed",
      edit_discount: "Could not edit this discount",
      duplicate: "Could not duplicate discount",
      delete_discount: "Deleting this discount failed",
      get_discount: "Could not fetch the data for this discount",
      export: "Could not export discount",
    },
    staff: {
      add_staff: "Could not add staff member",
      remove_member: "Removing this member failed",
      get_profile: "Could not fetch the data for this member",
      get_staff_members: "Fetching staff members failed",
      update_permissions: "Failed to update user Permissions",
      update_user_information: "Failed to update user information",
      get_roles: "Could not fetch roles",
      get_permissions: "Could not fetch permissions",
    },
    onboarding: {
      create_store: "Sorry, we could not create your store",
      subdomain_taken: "This subdomain is taken",
      invalid_subdomain: "Invalid subdomain",
      industries_fetch: "Could not fetch the industries, please refresh the page",
    },
    collections: {
      add_collection: "Could not add collection",
      edit_collection: "Could not edit this collection",
      delete_collection: "Deleting this collection failed",
      get_collection: "Could not fetch the data for this collection",
      delete_product: "Could not delete this product",
      add_products: "Could not add products to the collection",
    },
    plans: {
      get_plans: "Could not fetch Zammit plans. Please contact support",
    },
    analytics: {
      get_data: "An error occurred while fetching the data",
      empty: "No data to display",
    },
    general_error: {
      title: "We encountered an error loading this page",
      subtitle: "Something went wrong. If it isn't fixed in a few minutes, please contact support.",
    },
    navigation: {
      get: "Could not fetch you Navigation elements",
      update: "Oops! Could not update the Navigation element(s)",
    },
    variants: {
      get_variants: "Could not fetch variants",
      update_variant: "Could not update the variant",
      over_limit:
        "Exceeded maximum export limit. Please note that the maximum export limit is 15,000 items. If you require a larger export, please contact our support team for assistance. Thank you.",
    },
    customers: {
      export: "Could not export customers",
      add_customer_address: "Could not add address",
      mark_address_default: "Could not mark address as default",
    },
    settings: {
      update_settings: "Could not update Company Settings",
      update_user_settings: "Could not update User Settings",
      delete_social_media_platforms: "Could not delete social media platform",
    },
    related_products: {
      update_related_products: "Failed to update related products",
      get_available_products: "Failed to fetch products",
    },
    products_reviews: {
      get_reviews: "Failed to fetch reviews",
    },
    own_locations: {
      get_location: "Could not fetch the data for this location",
      add_location: "Could not add location",
      edit_location: "Could not edit location",
    },
  },
  address: {
    address_line: "Address line",
    second_line: "Address Line 2",
    building: "Building",
    floor: "Floor",
    apartment: "Apartment",
    city: "Governorate",
    region: "Neighborhood",
    district: "District",
    country: "Country",
    postalCode: "Postal Code",
  },
  dashboard: {
    notifications_popup: {
      title: "Stay in the loop and receive instant updates on your latest orders by turning on browser notifications",
      btn: "Manage Notifications",
    },
    home: "Home",
    orders: "Orders",
    draft_orders: "Abandoned carts",
    products: "Products",
    customers: "Customers",
    analytics: "Analytics",
    online_analytics: "Online store",
    zam_pos_analytics: "ZamPOS",
    discounts: "Discounts",
    website_builder: "Website builder",
    marketing: "Marketing",
    settings: "Settings",
    visit_store: "Visit store",
    partners: "Partners",
    navigation: "Navigation",
    staff: "Staff",
    all_products: "All Products",
    inventory: "Inventory",
    pricing: "Pricing",
    collections: "Collections",
    vendors: "Vendors",
    subscription: "Subscription",
    order_search_placeholder: "Search by order Id, customer or items name",
    product_search_placeholder: "Search by product name, SKU or barcode",
    filters: "Filters",
    sort: "Sort",
    select: "Select",
    reset: "Reset",
    cancel: "Cancel",
    discard: "Discard",
    done: "Done",
    add: "Add",
    export: "Export",
    import: "Import",
    import_instagram_products: "Import from Instagram",
    clear: "Clear",
    apply: "Apply",
    more_options: "More options",
    items_selected: "Items Selected",
    edit_selected: "Edit Selected",
    clear_filters: "Clear all filters",
    select_all: "Select All",
    unselect_all: "Unselect All",
    free_trial: "Free Trial Plan",
    logout: "Logout",
    send_sms: "Send SMS",
    borrow_and_grow: "Borrow & Grow",
    paymob_self_onboarding: "Receive Payments by Paymob",
    "e-tax": "E-Tax",
    related_products: "Related Products",
    products_reviews: "Product Reviews",
    select_all: "Select All",
    unselect_all: "Unselect All",
    plan: "{name} Plan",
    demo: "Demo Shop",
  },
  orders: {
    all_orders: {
      title: "Orders List",
      only_new: "Show only new orders",
      shipping_by: "Shipping by",
      actions: {
        ready_for_pickup: "Mark as ready for pickup",
        on_route: "Mark as in transit",
        delivered: "Mark as delivered",
        delivery_failed: "Mark as delivery failed",
        package_returned: "Mark as returned",
        paid: "Mark as paid",
        paid_confirmation: "Are you sure you want this order to be marked as paid?",
        accept_paid_confirmation: "Yes Mark as Paid",
        allow_customer_to_open: "Allow to open package",
        airway_bill: "Print airway bill",
        e_invoice: "Generate E-Invoice",
        cancel: "Cancel order",
        manage_order_on_own: "Manage order on my own ",
      },
      couriers: {
        title: "Choose courier",
        btn: "Select",
      },
      bulk_update_success: "Successfully updated orders",
      bulk_airway_bill_success: "Successfully generated airway bills",
      submit_multiple_shipments_success: "Successfully sent orders to courier",
    },
    draft_orders: {
      title: "Abandoned Carts",
      actions: {
        delete: "Delete order",
      },
    },
    orders_list: {
      table_headers: {
        order_id: "Order ID",
        placed_on: "Placed on",
        updated_at: "Updated at",
        customer: "Customer",
        value: "Value",
        channel: "Channel",
        fulfillment_status: "Courier fulfillment status",
        payment_status: "Payment status",
        payment_method: "Payment method",
        shipping: "Shipping",
      },
      channel: {
        online_store: "Online store",
        point_of_sale: "ZamPOS",
        dashboard: "Dashboard",
      },
      payment: {
        status: {
          unpaid: "Unpaid",
          paid: "Paid",
          refunded: "Refunded",
        },
        method: {
          cash_on_delivery: "Cash on delivery",
          zampos_cash_on_delivery: "Cash",
          online: "Online card by Paymob",
          geidea: "Online card by Geidea",
          gccpay: "Online card by Gccpay",
          stripe: "Online card by Stripe",
          paytabs: "Online card by PayTabs",
          valu: "ValU",
          card_on_delivery: "Card on delivery",
          zampos_card_on_delivery: "Card",
          sympl: "Sympl",
          bank_installments: "Bank installments",
          mobile_wallets: "Mobile wallets",
          bank_transfer: "Bank transfer",
          souhoola: "Souhoola",
          tabby: "Tabby",
          forsa: "Forsa",
        },
      },
      couriers: {
        own: "On your own",
        r2s: "R2S",
        beyond: "Beyond",
        hybrid: "Hybrid",
        picks: "Picks",
        mylerz: "Mylerz",
        voo: "VOO",
        loadbugs: "Loadbugs",
        bosta: "Bosta",
        khazenly: "Khazenly",
        eyedel: "Eyedel",
      },
      sort: {
        id: "Order no.",
        placed_on_new: "Newest to oldest",
        placed_on_old: "Oldest to newest",
        updated_at_new: "Newest to oldest",
        updated_at_old: "Oldest to newest",
        customer_name: "Customer name (A-Z)",
        payment_status: "Payment Status",
        fulfillment_status: "Courier Fulfillment Status",
        value_low: "Value (Low to high)",
        value_high: "Value (High to low)",
      },
      filters: {
        status: {
          title: "Status",
          values: {
            draft: "Draft",
            active: "Active",
            canceled: "Canceled by merchant",
            archived: "Archived",
          },
        },
        fulfillment_status: {
          title: "Courier Fulfillment Status",
          values: {
            unfulfilled: "New order",
            on_route: "In transit",
            delivered: "Delivered",
            cancelled: "Cancelled by courier",
            delivery_failed: "Delivery failed",
            at_warehouse: "At warehouse",
            package_returned: "Returned",
            ready_for_pickup: "Pending pick up",
            delayed: "Delayed",
          },
        },
        payment_status: "Payment Status",
        payment_method: "Payment method",
        vendors: "Vendors",
        shipping: {
          title: "Shipping",
          values: {
            own: "On your own",
            r2s: "R2S",
            beyond: "Beyond",
            hybrid: "Hybrid",
            picks: "Picks",
            mylerz: "Mylerz",
            voo: "VOO",
            loadbugs: "Loadbugs",
            bosta: "Bosta",
          },
        },
        channel: "Channel",
        placed_on: {
          title: "Placed on date",
          placeholder: "Select date, date range ...",
        },
        discount: "Promo Code",
        no_data: "No orders were found to match your search",
        modify_search: "Try modifying your search criteria",
      },
      actions: "Actions",
      no_orders: "You don't have any orders yet",
      no_draft_orders: "You don't have any abandoned carts yet",
      view_orders: "View all Orders",
    },
    order_details: {
      title: "Order #{id}",
      placed_on: "Placed on",
      order_items: {
        title: "Order items",
      },
      order_note: {
        title: "Customer note",
      },
      order_customer: {
        title: "Customer info",
      },
      order_sender: {
        title: "Sender info",
      },
      order_recipient: {
        title: "Recipient info",
      },
      order_seller: {
        title: "Order seller",
      },
      order_address: {
        country: "Country",
        city: "City",
        area: "Area",
        district: "District",
        address_line: "Address line",
        building: "Building",
        floor: "Floor",
        apartment: "Apartment",
        copy_location: "Copy location",
        get_directions: "Get directions",
      },
      order_payment: {
        title: "Payment",
        paymob_link: "Open in Paymob",
        subtotal: "Subtotal",
        taxes: "Taxes",
        cod_fee: "Cash/Card On Delivery Fees",
        shipping_fees: "Shipping fees",
        add_shipping: "Add Shipping Fees",
        discount: "Discount",
        add_discount: "Add Discount",
        discount_amount: "Discount Amount",
        total: "Total",
        apply: "Apply Discount",
        cancel: "Cancel",
        select: "Select one option",
        discount_title: "Enter Discount name",
        use_discount: "Use existing Discount",
        custom_discount: "Custom Discount",
        promo_code: "Promo code used",
        get_discount: "Could not fetch the data for this discount",
        invalid_discount: "Invalid Discount",
        invalid_discountValue: "Discount value can't be zero",
      },
      allow_customer_to_open: " Allow customer to open this package",
      order_shipping: {
        title: "Shipping",
      },
      view_file: "Click here to view file",
      order_logs: {
        title: "Order logs",
        created: "Order created",
        by: "by",
        staff: "staff member",
        statuses: {
          paid: "Order is paid",
          on_route: "Order is on route",
          cancelled: "Order is cancelled",
          delivered: "Order is delivered",
          package_returned: "Order is returned",
          at_warehouse: "Order is at warehouse",
          ready_for_pickup: "Order is ready for pickup",
          delivery_failed: "Order delivery failed",
        },
        courier: "courier",
      },
    },
    new_order: {
      browse_customers: {
        title: "Customer",
        search_placeholder: "Search customers",
        add_new: "Add new",
      },
      browse_products: {
        title: "Products",
        search_placeholder: "Search by product name, SKU or barcode",
      },
      products_list: {
        quantity_range: "Minimum Quantity is {min} and Maximum Quantity is {max}",
      },
      mark_paid: {
        title: "Mark as paid",
        body: "Are you sure you want to mark this order as paid?",
        confirm: "Yes, mark as paid",
      },
      leave_page: {
        title: "Leave Page",
        body: "All changes made won’t be saved",
        unsaved_changes: "If you leave this page, any unsaved changes will be lost.",
        continue_editing: "Continue Editing",
      },
      notes: {
        title: "Notes",
        add_note: "Add Note",
      },
      delivery: {
        title: "Delivery",
        require_delivery: "Does this order require delivery? ",
        confirm_delivery: "Yes, I want to deliver this order",
        discard_delivery: "No, I do not want to deliver this order",
      },
      shipping: {
        add: "Add Shipping",
        cancel: "cancel",
        apply: "Apply Shipping",
        select: "Select One Option",
        standard: "Standard shipping",
        free: "Free Shipping",
        custom: "Custom",
        custom_shipping: "Custom Shipping price",
        custom_price_error: "Custom price can't be 0 or less",
      },
    },
  },
  products: {
    modules: {
      media: "Media",
      basicInfo: "Basic Info",
      pricing: "Pricing",
      inventory: "Inventory",
      variants: "Variants",
      categorization: "Categorization",
      shipping: "Shipping",
      extras: "Extras",
      status: "Product Status",
    },
    add_product: {
      media: {
        header: "Media",
        add_media: "Add Media",
        drag_upload: "or drag files to upload",
        type_and_size: "Accepts JPEG, PNG - Max ( 10 MB )",
        thumbnail: "Thumbnail",
        delete_modal: {
          title: "Delete 1 media file?",
          body: "Are you sure you want to delete this media file? This action can't be reverted",
          cancel: "Cancel",
          confirm: "Yes, Delete",
        },
      },
      basic_info: {
        header: "Basic Info",
        title: "Title",
        description: "Description",
        show_source: "Show actual source",
        url: "URL",
        ribbon: {
          label: "Ribbon",
          tooltip:
            "Add a text label like “New Arrival” to make this product stand out. It’ll be displayed on your product card in the shop.",
        },
        tooltip: "Hey! You can always enter your info in both languages. Don’t worry, we will store both.",
      },
      search_engine_listing: { title: "Search Engine Listing" },
      variants: {
        deprecated: {
          using_features: "Using deprecated features",
          message:
            "{povs} have additional fees associated. Editing this product will remove those fees because this feature is deprecated. Use variants price instead.",
          modal: {
            title: "Product change",
            subtitle: "Are you sure you want to make changes to this product?",
            text: "Please notice that this product has additional fees option that you'll need to re-enter manually if you made any changes",
            confirm: "Yes, make changes",
          },
        },
        duplicate: "Duplicate",
        enter_hint: "Press enter to add a value",
        header: "Variants",
        toggle_title: "This product has variants",
        toggle_subtitle:
          "Sell different versions of the same product. For example, a shirt the comes in multiple colors or sizes.",
        add: "Add variants",
        variants: "Variants",
        variant: "Variant",
        options: "Options",
        options_title: "Add your product options",
        new_option: "New option",
        variants_title: "Variants info",
        price: "Price",
        sku: "SKU",
        quantity: "Quantity",
        max_reached: "Maximum number of variants ({num}) reached",
        max_reached_warning:
          "Adding more than {num} variants will affect your store performance significantly. We do not recommend this. Instead, you can try using our extras feature to add more options to your product.",
        delete_modal: {
          title: "Remove variant options",
          text: "Deleting the selected option will delete the following",
          cancel: "Cancel",
          confirm: "Yes, delete",
        },
      },
      extras: {
        header: "Customize your product",
        toggle_title: "This product has extras that can be added to it",
        small_secondary_header: "Sell different extras on top of the same product",
        secondary_header:
          "Sell different extras on top of the same product or collect custom information when customers add this product to their cart" +
          "\nExample: (A cake with extra chocolate sauce, name engraved on wooden craft , reservation date for a nile cruise , etc …)",
        field_type: "Field type",
        view_type: "View type",
        error_min: "Invalid minimum value",
        error_max: "Invalid maximum value",
        field_label: "Field label",
        min_options: "Minimum selected option(s)",
        max_options: "Maximum selected option(s)",
        min_chars: "Minimum character(s)",
        max_chars: "Maximum character(s)",
        new: "New extra",
        extra_fees: "Extra fees?",
        extra_fees_subtitle: "Mark it if you are charging extra fees to your product price.",
        fixed_fees: "Fixed fees",
        price_per_letter: "Per letter entered fees",
        required: "Required",
        required_subtitle: "Mark it if this field is required for your store users to checkout.",
        extra_types: {
          datetime: "Date/time",
          dropdown: "Dropdown",
          text: "Text field",
          file: "File Upload",
        },
        extra_view_types: {
          multi_select: "A dropdown with multiple options",
          checkbox: "A list of checkboxes",
        },
      },
      categorization: {
        header: "Categorization",
        type: "Type",
        vendor: "Vendor",
        tags: "Tags",
        dialog_label: "View all tags",
        select_tags: "Select tags",
        existing_tags: "Existing Tags",
        selected_tags: "Selected Tags",
        tags_description: "Selected tags from above list will appear here",
        preview: "Type, Vendors & Tags",
        no_collections: "No collections available",
        collection: "Collection",
        google_product_category: "Product Category",
      },
      pricing: {
        header: "Pricing",
        discounted: "Discounted Price",
        sale: "On sale",
        vat: "Charge VAT on this product",
        priced_by: {
          title: "Priced by",
          options: {
            unit: "Unit",
            product_length: "Length",
            area: "Area",
            weight: "Weight",
          },
        },
      },
      inventory: {
        header: "Inventory",
        sold_out: "Mark product as sold out",
        sku: "SKU",
        barCode: "Barcode",
        unlimited_title: "Unlimited inventory",
        unlimited_subtitle: "Enable this option if inventory shouldn't be tracked.",
        quantity: "Available quantity",
        limit: "Ordering quantity limitations",
        min: "Minimum order quantity",
        max: "Maximum order quantity",
      },
      shipping: {
        header: "Shipping",
        add_shipping_toggle_title: "This product has shipping fees",
        is_physical_toggle_title: "This is a physical product",
        weight: "Weight",
        unit: "Unit",
        on_hint: "Used to calculate shipping rates at checkout and label prices during fulfillment",
        off_hint:
          "Your customers won't enter their shipping address or choose a shipping method when buying this product",
        units: {
          kg: "KG",
          g: "G",
        },
      },
      status: {
        header: "Product status",
        status: "Status",
        hint: "This product will be hidden until you turn it into Active.",
        values: {
          hidden: "Draft",
          visible: "Active",
          sold_out: "Sold out",
        },
      },
    },
    product_details: {
      set_active: "Set as active",
      set_draft: "Set as draft",
      duplicate: "Duplicate",
      archive: "Archive",
      delete: "Delete",
      success_message: 'Added "{title}"',
      add_product: "Add another product",
      product_not_found: "Product not found",
      delete_product: "Delete product",
      archive_product: "Archive product",
      duplicate_modal: {
        modal_title: "Duplicate product",
        title: "Title",
        select_details: "Select details to copy to the new product",
        copy: "Copy",
        duplicate: "Duplicate",
      },
      delete_modal: {
        title: "Delete product?",
        body: "Are you sure you want to delete this product? This action can't be reverted",
        cancel: "Cancel",
        confirm: "Yes, Delete",
      },
      variants: {
        variants: "Variants",
        num_variants: "{num} variants",
        new_variant: "Add new variant",
        manage_options: "Manage options",
        manage_options_modal: {
          unsaved_changes: "You have some unsaved changes",
          text: "you have to save or discard the changes you have made to this product in order to manage its options",
        },
        number_variants: "{number} variant(s)",
        back_product: "Back to product",
        options: "Options",
        add_media: "Add media",
        choose_existing: "or choose existing",
        select_variant_image: "Select variant image",
        select_variant_image_text: "Select from available images or upload a new one",
      },
    },
    product_reviews_details: {
      close: "Close",
      hide_all_reviews: "Hide all Reviews",
      hide_all_reviews_hint: "Activating this toggle will hide the product reviews",
      reviews: "Reviews",
      pagination: {
        prev: "Previous",
        next: "Next",
      },
    },
    all_products: {
      sort: {
        title: "Product title (A-Z)",
        recent: "Recently added",
        price_low: "Price (Low to high)",
        price_high: "Price (High to low)",
      },
      actions: {
        visible: "Set as active",
        hidden: "Set as draft",
        delete: "Delete",
        sold_out: "Set as sold out",
        view_details: "View Details",
      },
      products_list: {
        title: "Products List",
        num_items: "({num} Items)",
        products: " product(s)",
        view_products: "View products",
        in_stock: "in stocks",
        variants: "variants",
        out_stock_message: "Hey! you have {num} product(s) out of stock",
        dismiss: "Dismiss",
        actions: "Actions",
        no_products: "You don't have any products yet",
        add_first: "Add your first product",
        stock: {
          out_of_stock: "Out of stock",
          in_stock: "{num} in stock",
          for_variants: "for {num} variants",
        },
        table_headers: {
          product: "Product",
          status: "Status",
          stock: "Stock",
          price: "Price",
          type: "Type",
          tags: "Tags",
        },
        edit_products: {
          name: "Edit {num} Product(s)",
          description: "",
        },
        set_active: {
          name: "Set {word} Active",
          description: "Setting a product as active will display it in your website",
        },
        set_draft: {
          name: "Set {word} Draft",
          description: "Setting a product as draft will hide it from your website",
        },
        add_collection: {
          name: "Add {word} to Collection(s)",
          description: "",
        },
        remove_collection: {
          name: "Remove {word} from Collection(s)",
          description: "",
        },
        duplicate_product: {
          name: "Duplicate {num} Product(s)",
          description: "",
        },
        delete_product: {
          name: "Delete {num} Product(s)",
          description: "",
        },
      },
      filters: {
        stock_filter: "Show only out of stock products",
        variants_filter: "Show only products that have variants",
        collection: "Collection",
        status: "Status is",
        type: "Type",
        is: "is",
        are: "are",
        and: " & ",
        more: "more ",
        stock: "Only out of stock",
        variants: "Has variants",
        no_data: "No products were found to match your search",
        modify_search: "Try modifying your search criteria or ",
        add_product: "creating a new product",
        vendors: "Vendors ",
      },
      products: {
        browse: "Browse products",
        select_products: "Select products",
        cancel: "Cancel",
        add_num: "Add ({num})",
        add_products: "Add ({num}) products",
        show_extras: "Show Extras",
        length_label: "Enter length ({unit})",
        width_label: "Enter width ({unit})",
        weight_label: "Enter weight ({unit})",
      },
    },
    instagram: {
      select_posts: "Select products to import",
      submit: "Submit",
      select_all: "Select All",
      deselect_all: "Deselect All",
    },
    products_reviews: {
      title: "Product Reviews",
      no_reviews: "No reviews available",
      table_headers: {
        product: "Product",
        reviews: "Reviews",
      },
    },
  },
  collections: {
    sorting: {
      sort_by: "Sort by: ",
      manual: "Manual",
      price_asc: "Price (low to high)",
      price_desc: "Price (high to low)",
      name_asc: "Name (A to Z)",
      name_desc: "Name (Z to A)",
      created_at_asc: "Creation Date (oldest to newest)",
      created_at_desc: "Creation Date (newest to oldest)",
    },
    parameters: {
      id: "All Products",
      name: "Name",
      tags: "Tags",
      discounted_price_cents: "Discounted price",
      is_on_sale: "On Sale",
      price_cents: "Price",
      type: "Type",
      vendor: "Vendor",
      quantity: "Quantity",
    },
    values: {
      true: "Yes",
      false: "No",
    },
    operators: {
      eq: "Equal to",
      matches: "Includes",
      not_eq: "Not equal to",
      does_not_match: "Doesn't include",
      gt: "Greater than",
      lt: "Less than",
      any: "Equal to",
    },
    all_collections: {
      plus_more: "+ {num} more...",
      is: "is",
      title: "Collections List",
      num_items: "({num} Collections)",
      search_placeholder: "Search by collection name",
      search_placeholder_mobile: "Search",
      collection_type: "Collection Type",
      no_collections: "You don't have any collections created yet",
      create_collection: "Start creating your first collection",
      add_collection: "Add collection",
      products: "Product(s)",
      sort: "Sort",
      filters: "Filters",
      type: "Type",
      manual: "Manual",
      smart: "Smart",
      smart_collection: "Smart collection",
      browse_all_collections: "Browse all collections",
      column_headers: {
        name: "name",
        num_of_items: "# of products",
        conditions: "conditions",
        type: "type",
      },
      sort: {
        recent: "Recently added",
        count_low: "Count (Low to high)",
        count_high: "Count (High to low)",
      },
    },
    new_collection: {
      type: {
        header: "Collection type",
        manual: "Manual",
        manual_subtitle: "Manually add products to this collection",
        smart: "Smart",
        smart_subtitle: "Describe the product properties and they will update automatically",
      },
      products: {
        add: "Add product",
        browse: "Browse products",
        search_hint: "Search by product name, SKU or barcode",
        cancel: "Cancel",
        add_num: "Add ({num})",
        add_products_num: "Add product ({num})",
        no_products: "No products added to this collection yet, start adding them.",
        browse_all: "Browse all products",
        preview_title: "Products",
        preview_subtitle: "{num} product(s) added",
      },
      conditions: {
        preview_title: "Conditions",
        preview_subtitle: "{num} condition(s) added",
        globalOperator: {
          title: "Products must match",
          and: "All conditions",
          or: "Any condition",
        },
        new: "New conditions",
        parameter: "Parameter",
        condition: "Condition",
        automatic_products: "Automatically added products",
        hint: "Please note that, these products are added automatically based on the conditions you previously set and can neither be edited nor deleted.",
      },
    },
    collection_details: {
      delete_modal: {
        title: "Delete collection?",
        body: "Are you sure you want to delete this collection? This action can't be reverted",
        cancel: "Cancel",
        confirm: "Yes, Delete",
      },
    },
    browse_modal: {
      title: "Browse Collections",
      search_hint: "Search by collection name",
      cancel: "Cancel",
      add_num: "Add ({num})",
    },
  },
  discounts: {
    modules: {
      basicInfo: "Basic Info",
      value: "Value",
      conditions: "Conditions",
      variants: "Variants",
      usage: "Usage",
      buyXGetYConditions: "Buy X Get Y Conditions",
    },
    add_discount: {
      basic_info: {
        header: "Basic Info",
        code_name: "Promo code name",
        tooltip: "Customers will enter this name at checkout",
        discount_method: [
          "This discount will be applied by entering a promo-code at checkout",
          "This discount will be automatically applied at checkout",
        ],
      },
      type: {
        header: "Type",
        ProductsDiscount: "Amount off Products",
        BillDiscount: "Amount off Order",
        BuyXGetYDiscount: "Buy X Get Y",
        ShippingDiscount: "Amount off Shipping",
      },
      value: {
        header: "Value",
        discount_value: "Discount Value",
        fixed_amount: "Fixed",
        percentage: "Percentage",
        free_shipping: "Free Shipping",
      },
      conditions: {
        header: "Conditions",
        none: "None",
        minPurchase: "Minimum purchase amount",
        minProductQuantity: "Minimum Product Quantity",
        is_x_exact_match: "The customer has to buy ALL of the specified items for discount to apply",
        is_y_exact_match: "The customer has to have ALL of the specified items in their purchase",
        set_x_match:
          "How many times should customer buy all items in Customer Buys List in order to apply this discount",
        unset_x_match: "What is the minimum number of products that should be bought in order to apply this discount",
        max_y_quantity: "What is the maximum amount of items the customer gets ?",
      },
      usage: {
        header: "Usage",
        customers: {
          title: "Who can use it?",
          everyone: "Everyone",
          specific_customers: "Specific Customers",
        },
        limits: {
          title: "Any usage limits?",
          max_uses_label: "Total Usage Limit",
          max_uses: "The code has a total usage limit across the whole store.",
          once_per_customer: "A customer is limited to redeem the code only once",
          only_signed_in_customers: "Only allow logged in customers",
        },
      },
      dates: {
        header: "Dates",
        startDate: "Start Date",
        endDate: "End Date",
        setEndDate: "Set an End Date",
        selectStartDate: "Select Start Date",
        selectEndDate: "Select End Date",
      },
      errors: {
        code_error: "Promo code should be one word only without spaces",
      },
      select: "Select",
      select_from: "Selected from",
      apply_items: "Applies only on these products",
      CustomerBuys: "Customer Buys",
      CustomerGets: "Customer Gets",
      types: {
        specific_products: "Specific products",
        specific_collections: "Specific collections",
        everything: "Everything",
        Product: "Product",
        Collection: "Collection",
      },
      example: "Try changing the number to see responsive examples",
      clarification: {
        title: "Clarification",
        set_exact_match_description:
          "Suppose your Selected Items in Customer Buys list are a bag and a shoe. Ticking the above checkbox and setting the text field to { num } would mean that :-",
        unset_exact_match_description:
          "However, If you don't tick the checkbox and set the text field to { num } that would mean:-",
        exact_match_description: "Customers would have to buy {num} of each product",
        exact_match_example: "Example { num } bag(s) AND { num } sock(s)",
        inexact_match_description: " Customers would have to buy a TOTAL of { num } product(s)",
        inexact_match_example: " Example: { firstVal } Bag(s) and { secondVal } Sock(s)",
        inexact_match_second_example: "Example: { num } Bag(s)",
        inexact_match_third_example: "Example: { num } Sock(s)",
      },
    },
    all_discounts: {
      title: "Discounts",
      discount_search_placeholder: "Search by promo code name",
      filters: "Filters",
      sort: "Sort",
      select: "Select",
      export: "Export",
      add_promo_button: "Add promo code",
      promo_code: "Promo codes",
      automatic_discounts: "Automatic Discounts",
      actions: {
        title: "Actions",
        visible: "Enable discount codes",
        hidden: "Disable discount codes",
        delete: "Delete discount codes",
      },
      column_headers: {
        code: "Code name",
        details: "Details",
        conditions: "Conditions",
        usage: "Usage ",
        status: "Status",
        date: "Date",
        count: "Count",
      },
      no_promo_codes: "You don't have any promo codes created yet",
      add_promo_codes: "Start adding your first promo code",
      details: {
        percentage: "{val} % off",
        fixed_amount: "{val} off",
        BillDiscount: "Order",
        ShippingDiscount: "Shipping",
        BuyXGetYDiscount: "Buy X Get Y",
        ProductsDiscount: "for each of {buyNum} product(s)",
        automatic: "Applies automatically",
        manual: "Applies on adding a promo-code",
      },
      conditions: {
        no_min_req: "No minimum required",
        min_req: "{val} required",
        max_uses: "Limited to {val} uses",
        unlimited: "Unlimited uses",
        only_signed_in_customer: "Only logged in customers",
        specific_customers: "{val} customers allowed",
        unlimited_customers: "Allowed for everyone",
        once_per_customer: "One use per customer allowed",
        all_products: "Valid on all products",
        specific_collections: "Valid on {val} collections",
        specific_products: "Valid on {val} products",
      },
      dates: {
        from: "From { date }",
        select_start_date: "Select start date (yyyy/mm/dd)",
        select_end_date: "Select end date (yyyy/mm/dd)",
        invalid: "Invalid date!",
      },
    },
    discount_details: {
      set_active: "Set as active",
      set_inactive: "Set as inactive",
      delete: "Delete",
      success_message: 'Added "{title}"',
      add_discount: "Add another discount",
      delete_discount: "Delete discount",
      delete_modal: {
        title: "Delete discount?",
        body: "Are you sure you want to delete this discount? This action can't be reverted",
        cancel: "Cancel",
        confirm: "Yes, Delete",
      },
    },
  },
  inventory: {
    title: "Inventory",
    product_search_placeholder: "Search by product name, SKU or barcode",
    search_placeholder_mobile: "Search",
    update: "Update",
    go_products: "Go to Products",
    stock: "in stock",
    updated_stock: "stock will be",
    filters: "Filters",
    export: "Export",
    column_headers: {
      product: "Product",
      status: "Status",
      stock: "Stock",
      edit_quantity: "Edit Quantity",
    },
    sort: {
      sort: "Sort",
      recent: "Recent",
      quantity_low: "Quantity (Low to high)",
      quantity_high: "Quantity (High to low)",
    },
  },
  pricing: {
    title: "Pricing",
    product_search_placeholder: "Search by product name, SKU or barcode",
    search_placeholder_mobile: "Search",
    update: "Update",
    go_products: "Go to Products",
    stock: "in stock",
    updated_stock: "stock will be",
    filters: "Filters",
    export: "Export",
    column_headers: {
      product: "Product",
      status: "Status",
      edit_pricing: "Edit Pricing",
    },
    sort: {
      sort: "Sort",
      recent: "Recent",
      price_low: "Price (Low to high)",
      price_high: "Price (High to low)",
      discounted_price_low: "Discounted price (Low to high)",
      discounted_price_high: "Discounted price (High to low)",
    },
    bulk_update: {
      title: "Add a % or a fixed amount in EGP to all prices",
      original_price: "Original Prices",
      update_original_price: "Update Original Prices",
      discounted_price: "Discounted Prices",
      update_discounted_price: "Update Discounted Prices",
      update_products_prices: "Update Products Prices",
      recent: "Recent",
      price_low: "Price (Low to high)",
      price_high: "Price (High to low)",
      discounted_price_low: "Discounted price (Low to high)",
      discounted_price_high: "Discounted price (High to low)",
      adjustment_type: {
        increase: "Increase",
        decrease: "Decrease",
      },
    },
  },
  related_products: {
    title: "Related Products",
    select: "Select Related Products",
    selected_related_products: "Selected Related products",
    add_more_products: "Add More Products",
    add_products: "Add Products",
    product_search_placeholder: "Search by product name, SKU or barcode",
    search_placeholder_mobile: "Search",
    column_headers: {
      product: "Product",
      price: "Price",
      option: "Option",
      total_related_products: "Total Related Products",
    },
    show_selected: "Show Selected in this page",
    cancel: "Cancel",
    apply: "Apply",
    related_products_count: "{num} Related Products ",
    no_products: "You don't have any related Products yet",
    add_more_products: "Add More Products",
  },
  navigation: {
    title: "Navigation elements",
    status: {
      active: "Active",
      hidden: "Hidden",
    },
    edit_label: "Edit label",
    label_name: "Label name",
  },
  staff_management: {
    store_owner: "Store owner",
    staff: "Staff Management",
    add_staff_btn: "Add staff",
    edit_staff_title: "Edit staff",
    save: "Save change",
    cancel: "Cancel",
    not_logged_yet: "Not logged in yet",
    store_profile: "Store Profile",
    add_staff: {
      first_name: "First Name",
      last_name: "Last Name",
      phone_number: "Phone Number",
      email: "Email Address",
    },
    edit_staff: "Edit",
    delete_staff: "Delete",
    select_permissions: "Select Permissions for the staff you're inviting",
    edit_select_permissions: "Edit Staff Permissions",
    select_role: "Select a Role for the staff you're inviting",
    other: "Other",
    assign_permissions: "Assign Permissions separately below",
    modules: {
      collection: "Collection",
      product: "Product",
      purchase: "Order",
      analyticsIntegrations: "Analytics Settings",
      discount: "Discount",
      navigation: "Navigation",
      analytics: "Analytics",
      customer: "Customer",
      websiteBuilder: "Website Builder",
    },
    select_all: "(Select all)",
    table_headers: {
      name: "Name",
      email: "Email",
      phone_number: "Phone Number",
      role: "Role",
      last_login: "Last Login",
      action: "Action",
    },
  },
  pick_plan: {
    address: {
      amazing_choice: "Amazing choice {name}, 👍",
      get_started: "We’ll help you get started in few quick steps",
      business_address: "Add your business address",
      save: "Save address",
    },
    title: "Flexible pricing for everyone",
    subtitle:
      "Whether you’re just getting started with selling online or scaling across the world, we’ve got you covered.",
    choose_billing_frequency: "Choose your billing frequency",
    choose_plan: "Choose your plan",
    monthly: "Monthly",
    quarterly: "Quarterly",
    semi: "Semi-Annual",
    annually: "Annual",
    biannually: "2 Years",
    bronze: "Bronze",
    silver: "Silver",
    gold: "Gold",
    zampos: "ZamPOS",
    subscribe: "Subscribe",
    most_popular: "Most popular",
    exclude_vat: "Excluding VAT",
    save_percent: "Save {value}%",
    learn_more_about_plan_details: "Learn more about plans details",
    description: {
      bronze: "If you're starting your business and building your first website",
      silver: "Most popular for growing businesses that was once a simple idea",
      gold: "Best for small and medium companies ongoing a digital transformation",
      zampos: "A light solution to mange your offline sales",
    },
    frequency_months: {
      monthly: "",
      quarterly: "x 3 months",
      semi: "x 6 months",
      annually: "x 12 months",
      biannually: "x 24 months",
    },
    offers: {
      ramadan: {
        offer: "Ramadan offer",
        buy2get1: "Buy two years and get the third year for free. T&Cs apply",
        banner: "{num} days left in Ramadan offer. Hurry up to get the most out of Zammit 🔥",
      },
    },
  },
  chips: {
    values: {
      hidden: "Draft",
      visible: "Active",
      sold_out: "Archived",
      disabled: "Disabled",
      expired: "Expired",
      inactive: "Inactive",
      active: "Active",
      draft: "Draft",
      unfulfilled: "New order",
      on_route: "In transit",
      delivered: "Delivered",
      cancelled: "Cancelled",
      delivery_failed: "Delivery failed",
      at_warehouse: "At warehouse",
      package_returned: "Returned",
      ready_for_pickup: "Pending pick up",
      delayed: "Delayed",
      unpaid: "Unpaid",
      paid: "Paid",
      refunded: "Refunded",
      pickup: "Pickup Order",
      registered: "Registered",
      guest: "Guest",
      subscribed: "Subscribed",
      not_subscribed: "Not subscribed",
    },
  },
  home: {
    notifications: {
      title: "Have a look!",
      check_all: "Check all our updates",
      back_home: "Back to home",
      check_now: "Check now",
      dismiss: "Dismiss",
      refer: {
        title: "Refer & Win",
        subtitle: "For every friend you refer to Zammit who starts a plan, we extend your plan for 1 free month",
        win: "Win",
      },
      zampos: {
        title: "Sell Offline in your shop or bazar",
        subtitle:
          "Besides your website, use ZamPOS app to sell offline! Invite your team now and sync from the same inventory. Customers receive your receipts by SMS",
        redirect: "Download App",
      },
      feedback2022: {
        title: "Let us know what you think of Zammit in 2022",
        subtitle:
          "It's been a such a year building and growing together! We want to hear from you to make sure we grow together in 2023",
        cta: "Click Here",
      },
    },
    fresh_sign_ups: {
      welcome: "Hi {name}, Welcome to your store.",
      set_store: "Set up your store",
      set_store_subtitle: "Get your store up and running in few simple steps!",
      milestones: {
        hasCourier: "Choose how to ship your products",
        hasCustomDomain: "Connect your domain",
        hasPaymentMethod: "Add a way to get paid",
        hasProducts: "Add your first product",
      },
      theming: {
        title: "Design your website",
        subtitle: "Full control over your website design",
        customize: "Customize my theme",
      },
      trial_footer: "Days left in your trial. Upgrade to get the most out of Zammit",
      select_plan: "Select a plan",
    },
    active: {
      good_morning: "Good morning, {name} 👋",
      store_performance: "My store performance",
      all_reports: "See all reports",
    },
    new_builder_popup: {
      title: "Our new Website Builder is here",
      desc: "We made it easy for you! Create your theme now and customize it to fit your brand. We are confident that you will love our new website builder and we can’t wait for you to try it out!",
      btn: "Get started",
    },
  },
  charts: {
    last_n_days: "Last {num} days",
    today: "Today",
    yesterday: "Yesterday",
    week: "Last week",
    month: "Last month",
    quarter: "Last quarter",
    year: "Last year",
    all_time: "All time",
    custom: "Custom",
    compare: "Compared to {period}",
    prev_period: "from previous period",
    sales: {
      title: "Total sales",
      returns: "Returns",
      cancelled: "Cancelled",
      failed: "Failed",
    },
    sales_channels: {
      title: "Sales channels",
      types: {
        online_store: "Online store",
        point_of_sale: "ZamPOS",
        dashboard: "Dashboard",
      },
    },
    sales_people: {
      title: "Sales people",
    },
    order_count: {
      title: "Total order numbers",
      performance_status: "Performance status",
      orders: "orders",
    },
    order_status: {
      title: "Order Status",
      total_orders: "Total orders {num}",
    },
    order_average: {
      title: "Average order value",
    },
    payment_methods: {
      title: "Total paid and completed",
      types: {
        all: {
          cash_on_delivery: "Cash (on delivery)",
          online: "Online",
          valu: "Valu",
          card_on_delivery: "Card (on delivery)",
          sympl: "Sympl",
          bank_installments: "Bank installments",
          bank_transfer: "Bank transfer",
        },
        online_store: {
          cash_on_delivery: "Cash on delivery",
          online: "Online",
          valu: "Valu",
          card_on_delivery: "Card on delivery",
          sympl: "Sympl",
          bank_installments: "Bank installments",
          bank_transfer: "Bank transfer",
        },
        point_of_sale: {
          cash_on_delivery: "Cash",
          card_on_delivery: "Card",
          bank_transfer: "Bank transfer",
        },
      },
    },
    top_value: {
      title: "Top products by value",
    },
    top_units: {
      title: "Top products by units sold",
    },
    returning_customers: {
      title: "Returning customer rate",
    },
    list: {
      see_more: "See more",
      see_less: "See less",
    },
  },
  customers: {
    all_customers: {
      no_customers: "you don't have any customers yet",
      add_first_customer: "Start adding your first customer",
      add_customer: "Add Customer",
      filters: "Filters",
      search_placeholder: "Search for customer by name, phone number or order ID",
      show_only_subscribed: "Show only subscribed customers",
      sort: "Sort",
      total_orders: "Total Orders",
      table_headers: {
        name: "Customer name",
        orders_number: "No. of Orders",
        amount_spent: "Total Amount Spent",
        is_guest: "Status",
        subscribed_to_newsletter: "Newsletter Subscription",
      },
    },
    customers_list: {
      title: "Customers",
    },
    add_customer: {
      basic_info: {
        header: "Basic Info",
        first_name: "First name",
        last_name: "Last name",
        email: "Email address",
        phone_number: "Phone number",
      },
      address_info: {
        header: "Address",
        area: "Area",
        neighbourhood: "Neighbourhood",
        country: "Country",
        address_line: "Address line",
        building: "Building",
        floor: "Floor",
        apartment: "Apartment",
      },
    },
    customer_details: {
      customer_addresses: {
        title: "Customer addresses",
        add_address: "Add address",
        add_btn: "Add new address",
        default_btn: "Mark as default",
        default: "Default",
      },
      statistics: {
        title: "Customer Statistics",
        customer_date: "Customer for {num} months",
        last_order: "Last Order",
        last_order_date: "{num} months ago",
        total_spent: "Total spent to date",
        average_order_value: "Average Order Value",
        active_orders: "No. of Active Orders",
        cancelled_orders: "No. of Cancelled Orders",
        draft_orders: "No. of Abandoned Orders",
        gift_orders: "No. of Gift Orders",
        total_orders: "No. of Total Orders",
      },
      last_order_details: {
        title: "Last Order",
        draft: "from draft orders",
        active: "from active orders",
      },
    },
    filters: {
      amount_spent: "Amount Spent",
      date_placeholder: "Select Date Range",
      order_date: "Date of order",
      customer_date: "Date added as customer",
      no_data: "No customers were found to match your search",
      modify_search: "Try modifying your search criteria",
    },
  },
  settings: {
    general_settings: "General settings",
    admin_info_settings: "Admin Info",
    company_info_settings: "Company Info",
    regional_settings: "Regional Settings",
    checkout_settings: "Checkout Settings",
    company_address_settings: "Company Address",
    users_and_permissions: "Users & permissions",
    billing: "Billing",
    shipping: "Shipping & delivery",
    shippingDetails: "Shipping Info",
    integrations: "Integrations",
    marketing: "Marketing",
    loyalty: "Loyalty",
    payment: "Payment",
    paymob: "Paymob",
    geidea: "Geidea",
    gccpay: "Gccpay",
    stripe: "Stripe",
    paytabs: "PayTabs",
    analytics: "Analytics",
    Info: "Info",
    chat: "Chat Plugin",
    policies: "Policies",
    shop_customization: "Shop Customization",
    social_media: "Social Media Links",
    advanced_settings: "Advanced Settings",
    general_settings_tabs: {
      store_currency: {
        title: "Store currency",
        currency_note: "Please contact support to change your store currency",
      },
      paymob_receiving_currency: {
        title: "Paymob Receiving Currency",
        currency_note: "Please contact support to change your paymob receiving currency",
      },
      price_decimals: "Price Decimal Points",
    },
    integration_tabs: {
      geidea: {
        title: "Geidea",
        geideaApiPassword: "Api Password",
        geideaMerchantPublicKey: "Merchant Public Key",
      },
      gccpay: {
        title: "Gccpay",
        gccpayTerminalId: "Terminal Id",
        gccpayPassword: "Password",
        gccpayMerchantKey: "Merchant Key",
      },
      paymob_dashboard_btn: "Go to Paymob dashboard",
      paymob_banner: {
        title: "Setup Paymob",
        tag: "🌟 1200+ merchant using it 🌟",
        desc: "Accept online payments instantly and create seamless buying experiences using various secured payment methods.",
        btn: "Setup Paymob",
      },
      paymob: {
        title: "Paymob",
        paymobUsername: "Username",
        paymobHmacSecret: "HMAC Secret",
        paymobApiKey: "API key",
        paymobOnlineIntegrationId: "Online Integration ID",
        paymobCashIntegrationId: "Cash Integration ID",
        paymobPaymentIframeId: "Iframe ID",
      },
      stripe: {
        title: "Stripe",
        stripeApiKey: "Stripe API Key",
        stripePublicKey: "Stripe Public Key",
        stripeEndpointSecret: "Stripe Endpoint Secret",
      },
      paytabs: {
        title: "PayTabs",
        paytabsServerKey: "PayTabs Server Key",
        paytabsProfileId: "PayTabs Profile ID",
      },
      valu: {
        title: "Valu",
        paymobValuIntegrationId: "Valu Integration ID",
        paymobValuIframeId: "Valu Iframe id",
        valu_payment: "Do you want to use Valu Payment?",
        useValuPayment: {
          true: "Yes",
          false: "No",
        },
      },
      sympl: {
        title: "sympl",
        paymobSymplIframeId: "Sympl Iframe ID",
        paymobSymplIntegrationId: "Sympl Integration ID",
        sympl_payment: "Do you want to use Sympl Payment?",
        symplStoreCode: "Sympl Store Code ",
        symplStoreCodeHint: "Write store code as STR-XXX",
        useSymplPayment: {
          true: "Yes",
          false: "No",
        },
      },
      bank_installments: {
        title: "bank Installments",
        paymobBankInstallmentsIntegrationId: "Bank Installments Integration ID",
        paymobBankInstallmentsIframeId: "Bank Installments Iframe ID",
        bankInstallmentsPayment: "Do you want to use Bank Installments?",
        useBankInstallmentsPayment: {
          true: "Yes",
          false: "No",
        },
      },
      mobileWallets: {
        title: "Mobile Wallets",
        mobileWalletsPayment: "Do you want to use Mobile wallets Payment?",
        paymobMobileWalletsIntegrationId: "Mobile Wallets Integration ID",
        useMobileWalletsPayment: {
          true: "Yes",
          false: "No",
        },
      },
      tabby: {
        title: "Tabby",
        tabbyPayment: "Do you want to use Tabby Payment?",
        paymobTabbyIntegrationId: "Tabby Integration ID",
        useTabbyPayment: {
          true: "Yes",
          false: "No",
        },
      },
      souhoola: {
        title: "Souhoola",
        souhoolaPayment: "Do you want to use Souhoola Payment?",
        paymobSouhoolaIntegrationId: "Souhoola Integration ID",
        paymobSouhoolaIframeId: "Souhoola Iframe ID",
        useSouhoolaPayment: {
          true: "Yes",
          false: "No",
        },
      },
      googleSiteVerification: {
        title: "google site verification",
        googleAnalyticsKey: "Google Analytics Tracking ID",
        googleTagManagerKey: "Google Tag Manager ID",
        googleSiteVerificationKey: "Google Site Verification ID",
      },
      meta: {
        title: "meta",
        facebookPixelKey: "Facebook Pixel ID",
        accessToken: "Access Token",
        addMetaKey: "Add Meta Key",
      },
      tiktokPixel: {
        title: "tiktok pixel",
        tiktokPixel: "Tiktok Pixel ID",
        tiktokAccessToken: "Tiktok Access Token",
      },
      facebookChat: {
        title: "facebook chat",
        facebookChatKey: "Facebook Page ID",
      },
      mailChimp: {
        title: "mail chimp",
        mailchimpKey: "Mail Chimp API key",
      },
      gameball: {
        title: "gameball",
        gameballApiKey: "GameBall API key",
        gameballTransactionKey: "GameBall Transaction Key",
      },
    },
    info_tabs: {
      adminInfo: {
        title: "Admin Info",
        name: "Name",
        email: "Email",
        phoneNumber: "Phone Number",
      },
      companyInfo: {
        title: "Company Info",
        name: "Name",
        url: "URL",
        contactPhone: "Store Phone No.",
        defaultStoreLanguage: "Default Store Language",
        communicationLanguage: "Communication Language",
        referralCode: "Referral Code",
        postalCode: "Postal Code",
        contactEmails: "Contact Emails",
        emailHint: "Type Email then press Enter",
      },
      companyAddress: {
        title: "company Address",
      },
      regionalSettings: {
        title: "Regional Settings",
      },
      checkoutSettings: {
        title: "Checkout Customization",
        customization_btn: "Customize Now",
        description_mandatory: "Below are the mandatory fields, you can change the fields labels ",
        description_non_mandatory:
          "Below are the non-mandatory fields, you can change the fields labels, choose what fields are required and hide the unwanted fields.",
        mandatory_field: "Mandatory Fields",
        non_mandatory_field: "Non Mandatory Fields",
        customization_fields: {
          first_name: "First Name",
          phone_number: "Phone Number",
          country: "Country",
          city: "Governorate",
          region: "Neighborhood",
          district: "District",
          address_line1: "Address Line",
          last_name: "Last Name",
          email: "Email",
          building: "Building",
          floor: "Floor",
          apartment: "Apartment",
          postal_code: "Postal Code",
          notes: "Notes",
          required_field: "Required Field",
          discard_field: "Show field",
        },
        advanced_settings: {
          title: "Advanced Settings",
        },
        abandoned_checkout_email: {
          title: "Abandoned Checkout Email",
          description: "Do You Want To Send Automated Emails To The Abandoned Checkout?",
        },
        require_email_checkout: {
          title: "Require email in checkout",
          description: "Disabling This Feature Means That Your Customers Will Not Receive Purchase Confirmation Emails",
        },
        store_pickup: {
          title: "Pick Up From Store Option",
          pickup_location: "Add Pickup Toggle Label",
        },
        gift_option: {
          title: "Gift Orders",
          description: "Do you want to allow your customers to send gifts to others? Appears as a toggle at checkout",
        },
        pickup_subtitle: "You can enable this feature",
      },
      seo: {
        title: "SEO",
        description: "Description",
        keywords: "Keywords",
        hint: "Type keyword then press Enter",
        statement:
          "Search engine optimization (SEO) increases the quality and quantity of website traffic by increasing the visibility of a website or a webpage to users of a web search engine.",
      },
      socialMedia: {
        platform: "Platform",
        url: "URL",
      },
      companyLinks: {
        title: "Social Media Platforms",
        addPlatform: "Add Social Media Platform",
      },
      policies: {
        title: "Policies",
        shippingPolicyEn: "Enter your english shipping policy below",
        shippingPolicyAr: "Enter your arabic shipping policy below",
        shippingPolicyEnHint: "Ex: Orders will ship within 2-7 business days",
        shippingPolicyArHint: "Ex: Orders will ship within 2-7 business days",
        checkoutMessageEn: "Enter your english checkout message below",
        checkoutMessageAr: "Enter your arabic checkout message below",
        checkoutMessageEnHint: "Ex: Well done, your order has been placed! Thank you for shopping with us.",
        checkoutMessageArHint: "Ex: Well done, your order has been placed! Thank you for shopping with us.",
        returnAndRefundPolicyEn: "Enter your english returns and refunds policy below",
        returnAndRefundPolicyAr: "Enter your arabic returns and refunds policy below",
        returnAndRefundPolicyEnHint: "Ex: Items can be returned within 14 days",
        returnAndRefundPolicyArHint: "Ex: Items can be returned within 14 days",
        emailHeadline: "Enter your order confirmation head-line text below",
        emailHeadlineHint: "Ex: Merry Christmas, Ramadan Kareem, eid Mubarak",
        checkoutMessage: "Enter your checkout message below",
      },
      shop_customization: {
        title: "Shop Customization",
        price_template: {
          title: "Price template",
          desc: "(applies only for products with price range)",
          toggle: "Add custom template",
          price_template_ar: "Enter price template in arabic",
          price_template_en: "Enter price template in english",
          select_template: "Select one of following templates",
          note: "Price template must contain one of the following keywords or both (min, max)",
          error_message: "Template must contain min or max",
        },
      },
    },
    shipping_tabs: {
      shipping_details: {
        title: "Shipping Details",
        courier: "Select your own courier",
        shipping_key: "Shipping key",
        courier_activation:
          "Please note that in order to activate Zammit's courier services, a contract must be signed between your company and the service provider, Paymob. We will contact you to facilitate the process and get you started.",
        preferred_methods: "Select your preferred payment methods at checkout",
        add_vat: {
          label: "Add VAT",
          title: "Would you like to add 14% VAT to your prices upon checkout?",
          true: "Yes, add 14% VAT to my prices",
          false: "No, my prices already include VAT",
        },
        calculate_fees: {
          title: "Do you want Zammit to calculate the shipping fees for you?",
          at_cost: "Yes, I want Zammit to calculate the shipping fees for me",
          own_fees: "No, I want to enter my own shipping fees",
        },
        shipping_type: {
          title: "Are your shipping fees flat fee or weight based?",
          flat_fee: "Flat Fee",
          weight_based: "Weight Based",
        },
        voo: {
          key: "VOO Account Email",
          hint: "Enter your VOO Account Email Here",
        },
        mylerz: {
          key: "Mylerz Account",
          hint: "Enter your Mylerz Account Credentials As Follows USERNAME:PASSWORD",
          business_category: "Business Category",
          warehouse_name_title: "Warehouse Name",
          warehouse_name_hint: "i.e العاشر من رمضان المنطقه الصناعيه جنوب غرب ١٦ قطعه رقم ١٨٣",
          business_category_hint: "You can check your Business Category from Mylerz dashboard",
        },

        loadbugs: {
          key: "Bearer Token",
          hint: "Open your Loadbugs account, choose from the side menu Integrations > API > Copy the “Bearer Token” from the top middle > paste it above",
        },
        bosta: {
          key: "Bosta Account Email:Password",
          hint: "Write your Bosta’s email and password in this format email:password below (Kindly note that your password shouldn’t contain the character #)",
        },
        khazenly: {
          sign_in: "Sign in with your Khazenly account",
          success: "Successfully connected your Khazenly account",
          error: "Failed to connect your Khazenly account. Please try again.",
          khazenlyStoreName: "Khazenly Store Name",
          khazenlyClientId: "Khazenly Client ID",
          khazenlyClientSecret: "Khazenly Client Secret",
          khazenlyAccountName: "Khazenly Account Name",
        },
        eyedel: {
          key: "API Key",
          hint: "Copy the API Key from your Eyedel dashboard and paste it here",
        },
        shipping_fees: "Shipping Fees",
        extra_kilo_fees: "Extra Kilo Fees",
        useCardCollection: "Card on Delivery",
        useCashCollection: "Cash on Delivery",
        useOnlinePayment: "Online Payment by Paymob",
        useGeideaPayment: "Online Payment by Geidea",
        useGccpayPayment: "Online Payment by Gccpay",
        useStripePayment: "Online Payment by Stripe",
        usePaytabsPayment: "Online Payment by PayTabs",
        useBankTransfer: "Bank Transfer",
      },
      cart: {
        title: "Cart",
        minimum_order: "Enter your minimum order amount",
        minimum_shipping: "Enter your minimum order amount for free shipping",
        cod_fee: "Enter cash/card on delivery fees",
      },
      areas: {
        title: "Shipping Regions & Exceptions",
        available_cities: "Available cities for shipping",
        remove_address: "Remove Address",
        exceptions: {
          title: "Shipping Exceptions",
          add_exceptions: "Add Exceptions",
          shipping_fees: "Shipping Fees",
          additional_kilo_fees: "Additional Kilo Fees",
          hint: "Do not select a region if you want to apply the exception to all regions in this city",
        },
        disabled_regions: {
          title: "Disabled Regions",
          add_disabled_regions: "Add Disabled Regions",
        },
      },
      own_locations: {
        title: "Shipping Locations",
        available_locations: "Available Locations for shipping",
        disabled_regions: "Disabled Regions",
        table_headers: {
          country: "Country",
          city: "City",
          region: "Region",
          district: "District",
          custom_fee: "Shipping Fees",
          extra_kilo_price: "Additional Kilo Fees",
          action: "Action",
        },
        add_more: "Add more",
        add_location: "Add New Shipping Location",
        edit_location: "Edit Shipping Location",
        add_disabled_region: "Add New Disabled Region",
        apply_on_all_cities: "Apply on all cities",
        apply_on_all_regions: "Apply on all regions",
        apply_on_all_districts: "Apply on all districts",
        edit: "Edit",
        delete: "Delete",
        save: "Save Change",
        cancel: "Cancel",
      },
    },
    errors: {
      shipping: {
        missing_key: "Please enter shipping key",
        missing_payment: "Please select at least one payment method",
        missing_fee: "Please enter shipping fees",
      },
    },
    upgrade: "Upgrade",
    plan: "your plan to use { name } now",
    success_message: "Successfully updated company settings",
    user_success_message: "Successfully updated user settings",
  },
  partners: partner,
};
