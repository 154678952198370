import { api } from "boot/axios";
import { downloadFile } from "src/utils";
import { Notify } from "quasar";
import { formatCustomer, formatCustomerAddress } from "./serializers/customerSerializer";
const apiPrefix = "api/v2";
const customerPrefix = "customers";
const addressPrefix = "addresses";
const spendPrefix = "total_spending_range";

const CustomerAPI = {
  // *** Customers ***

  async getCustomers(query, pagination) {
    const response = await api.get(`${apiPrefix}/${customerPrefix}`, {
      params: {
        page: pagination.page,
        limit: pagination.limit,
        ...(pagination.order && { order: pagination.order }),
        ...(query.searchQuery && { search: query.searchQuery }),
        ...(query.purchaseQuery && { purchase_query: query.purchaseQuery }),
        ...(query.customerCreatedDate && {
          from_created_at: query.customerCreatedDate.from,
          to_created_at: query.customerCreatedDate.to,
        }),
        ...(query.subscribedToNewsletter && { subscribed_to_newsletter: query.subscribedToNewsletter }),
        includes: JSON.stringify([{ relation: "purchases" }, { relation: "addresses" }]),
        view: "minimal_details",
      },
    });
    return response.data;
  },

  async addCustomer(customer) {
    const payload = formatCustomer(customer);
    const response = await api.post(`${apiPrefix}/${customerPrefix}`, payload);
    return response.data;
  },

  async addCustomerAddress(customer, addressInfo) {
    const payload = formatCustomerAddress(customer, addressInfo);
    const response = await api.post(`${apiPrefix}/${addressPrefix}`, payload);
    return response.data;
  },

  async updateCustomerAddress(addressId, addressInfo) {
    const response = await api.put(`${apiPrefix}/${addressPrefix}/${addressId}`, addressInfo);
    return response.data;
  },

  async getCustomer(id) {
    const response = await api.get(`${apiPrefix}/${customerPrefix}/${id}`, {
      params: {
        includes: JSON.stringify([{ relation: "addresses" }]),
        view: "details",
      },
    });
    return response.data.data;
  },
  async importCustomers(file) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.post(`${apiPrefix}/${customerPrefix}/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.success;
  },
  async exportCustomers(name, query, pagination) {
    const response = await api.get(`${apiPrefix}/${customerPrefix}/export`, {
      params: {
        page: pagination.page,
        limit: pagination.limit,
        includes: JSON.stringify([{ relation: "purchases" }, { relation: "addresses" }]),
        ...(query.searchQuery && { search: query.searchQuery }),
        ...(query.subscribedToNewsletter && { subscribed_to_newsletter: query.subscribedToNewsletter }),
      },
      responseType: "blob",
    });

    if (response.headers["content-type"].includes("application/json")) {
      const message = JSON.parse(await response.data.text()).message;
      Notify.create({ message });
    } else {
      downloadFile(response.data, `${name}.xlsx`);
    }
  },
  async getSpendingRange() {
    const response = await api.get(`${apiPrefix}/${customerPrefix}/${spendPrefix}`);
    return response.data.range;
  },
};

export default CustomerAPI;
