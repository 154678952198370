import { api } from "boot/axios";

const apiPrefix = "api/v2";
const productPrefix = "products";
const reviewPrefix = "reviews";
const updateAllPrefix = "update_all";

const ReviewAPI = {
  async updateReviewStatus(review, productId) {
    const payload = {
      is_hidden: review.is_hidden,
    };
    const response = await api.put(`${apiPrefix}/${productPrefix}/${productId}/${reviewPrefix}/${review.id}`, payload);
    return response.data.data;
  },
  async updateAllReviewsStatus(status, productId) {
    const payload = {
      is_hidden: status,
    };
    const response = await api.put(
      `${apiPrefix}/${productPrefix}/${productId}/${reviewPrefix}/${updateAllPrefix}`,
      payload
    );
    return response.data.data;
  },
  async getReviews(productId, pagination) {
    const response = await api.get(`${apiPrefix}/${productPrefix}/${productId}/${reviewPrefix}`, {
      params: {
        page: pagination.page,
        limit: pagination.limit,
      },
    });
    return response.data.data;
  },
};

export default ReviewAPI;
