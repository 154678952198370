/* spell-checker: disable */

import partner from "./partner";

export default {
  general: {
    welcome_zammit: "أهلاً بكم في زامت",
    welcome_back: "أهلاً بيكم",
    welcome: "أهلاً",
    step: "خطوة",
    next: "التالي",
    skip: "تخطي",
    done: "إكمال",
    edit: "تعديل",
    save: "احفظ",
    cancel: "إلغاء",
    remove: "حذف",
    discard: "تجاهل",
    or: "أو",
    verify: "تحقق",
    previous: "السابق",
    store_language: "لغه المتجر الاساسيه",
    language: "اللغة",
    generic_error: "عفواً! حصل خطأ، حاول مرة كمان",
    no_internet: "لا يوجد اتصال بالإنترنت. تأكد من تشغيل wifi أو بيانات الجوال.",
    help: "المساعدة",
    privacy: "الخصوصية",
    terms: "الشروط",
    optional: "اختياري",
    value: "القيمة",
    price: "السعر",
    add_more: "أضف المزيد",
    items: "منتجات",
    available: "متوفرة",
    preview: "معاينة",
    back: "الى الخلف",
    new: "جديد",
    soon: "قريبا",
    add: "أضف",
    na: "غير متوفر",
    etc: "الى آخره",
    unauthorized_access: "ليس لديك أذونات للوصول إلى هذه الصفحة",
  },
  language_switcher: {
    desktop: "Change language to English",
    mobile: "English language",
    lang: "English",
  },
  headers: {
    home: "الصفحة الرئيسية",
    orders: "الطلبات",
    products: "المنتجات",
    collections: "المجموعات",
    inventory: "المخزن",
    pricing: "الأسعار",
    create_order: "أضف طلب جديد",
    staff_management: "إدارة طاقم العمل",
    staff_profile: "إدارة طاقم العمل",
    add_product: "أضف منتج",
    add_customer: "اضف العميل",
    all_products: "كل المنتجات",
    add_discount: "أضف برومو كود",
    add_collection: "أضف مجموعة",
    add_staff: "أضف عامل",
    product_list: "قائمة المنتجات",
    stock: "المخزن",
    variants: "أنواع المنتج",
    collections: "المجموعات",
    new_variant: "أضف نوع جديد",
    new_collection: "أضف مجموعة جديدة",
    trial_ended: "انتهت الفترة التجريبية المجانية",
    pick_plan: "إختر خطة أسعارك",
    renew_plan: "انتهت فترة خطتك",
    upgrade_plan: "قم بترقية خطتك",
    subscribe: "اشترك",
    add_address: "أضف العنوان",
    navigation: "تقسيم المجموعات",
    plan_details: "تفاصيل خطة الأسعار",
    analytics: "التقارير",
    online_analytics: "تقارير الأونلاين",
    zam_pos_analytics: "ZamPOS تقارير",
    payment_successful: "تم الدفع بنجاح",
    partners: "شركائنا",
    instagram_import: "استيراد Instagram",
    checkout_settings: "اعدادات الدفع",
    coming_soon: "قريبا",
    send_sms: "أرسل لعملاءك",
    borrow_and_grow: "استلف واكبر",
    paymob_self_onboarding: "استقبل مدفوعات بواسطة Paymob",
    customer_details: "تفاصيل العميل",
    "e-tax": "E-Tax",
    payment: "الدفع",
    integrations: "تكاملات",
    shipping: "شحن",
    company: "شركة",
    policies: "سياسات",
    social_media_links: "روابط وسائل التواصل الاجتماعي",
    shop_customization: "تعديلات المتجر",
    advanced_settings: "إعدادات متقدمة",
    general_settings: " الاعدادات العامة",
    users_and_permissions: "المستخدمون والأذونات",
    settings: "الاعدادات ",
    related_products: "منتجات ذات صله",
    products_reviews: "تقييم المنتجات",
    go_to_products: "الذهاب الى المنتجات",
    updates: "التحديثات",
  },
  auth: {
    first_name: "الاسم الأول",
    last_name: "اسم العائلة",
    full_name: "الاسم بالكامل",
    contact_phone: "رقم التليفون",
    email: "عنوان البريد الإلكتروني",
    signup_terms: {
      1: "بالتسجيل ، أنا أوافق على",
      2: "الأحكام والشروط",
    },
    finish_signup: "إنهاء التسجيل",
    redirect: "تم التسجيل بنجاح، دلوقتي هتتنقل لزامت",
    already_have_account: "هل لديك متجر بالفعل؟",
    ask_phone: "ما هو رقم هاتفك؟",
    ask_email: "ما هو عنوان بريدك الالكتروني؟",
    continue_email: "سجل بالبريد الإلكتروني",
    continue_phone: "سجل برقم الهاتف",
    continue_google_oauth2: "تواصل ب Google",
    continue_facebook: "تواصل ب Facebook",
    continue_apple: "تواصل ب Apple",
    receive_sms_verification_hint: "ستصلك رسالة نصية قصيرة تحتوي على رمز التحقق",
    phone_error_length: "يجب ألا يقل رقم التليفون عن 11 رقمًا",
    phone_error_start: "يجب أن يبدأ الرقم بـ 1",
    phone_error_start_and_length: "يجب أن يبدأ الرقم بالرقم 1 وأن ​​يتكون من 11 رقمًا على الأقل",
    enter_otp: "أدخل الرمز الذي أرسلناه إلى",
    confirm_number: "قم بتاكيد رقم تليفونك",
    got_no_code: "لم يصلك الرمز؟",
    resend_code: "أعد إرسال الرمز",
    wait: "انتظر لمدة 30 ثانية",
    create_your_password: "إنشاء كلمة السر",
    create_password: "أنشئ كلمة السر",
    enter_password: "أدخل كلمة السر",
    enter_phone: "يرجى إدخال رقم الهاتف الخاص بك",
    email_error: "ليس بريدًا إلكترونيًا صالحًا",
    trouble_sign_in: "هل تواجه مشاكل في تسجيل الدخول؟",
    send_us_email: "كلمنا على البريد الاليكتروني",
    password_length: "يجب أن تكون كلمة السر 8 أحرف على الأقل",
    password_special_char: "يجب أن تحتوي كلمة السر على رقم أو رمز واحد على الأقل",
    show_password: "عرض كلمة السر",
    sign_up: "اشتراك",
    sign_in: "تسجيل الدخول",
    login: "تسجيل الدخول",
    signin_continue: "سجل الدخول للمتابعة",
    not_you: "ليس انت",
    use_another_account: "استخدم حسابا آخر",
    forgot_password_text: "لقد نسيت كلمة السر",
    forgot_password: {
      title: "نسيت كلمة السر الخاصة بي",
      enter_email: "أدخل بريدك الإلكتروني",
      enter_email_subtitle:
        "أدخل عنوان البريد الإلكتروني المرتبط بحسابك ، وسنرسل لك رابطًا عبر البريد الإلكتروني لإعادة تعيين كلمة السر",
      email: "عنوان البريد الإلكتروني",
      reset_link: "أرسل رابط إعادة التعيين",
      success: "تم إرسال البريد الإلكتروني بنجاح",
      success_subtitle:
        "يرجى التحقق من البريد الوارد الخاص بك. تم إرسال ارتباط لإعادة تعيين كلمة السر الخاصة بك إلى {email}",
      update_password: "تغيير كلمة السر",
      create_password: "أنشئ كلمة السر",
      reenter_password: "إعادة إدخال كلمة السر الخاصة بك",
      update: "تحديث",
      token_missing_error: "الرجاء فتح الرابط المرسل إلى بريدك الإلكتروني مرة أخرى",
    },
  },
  explore_plans: {
    renew: {
      title: "اختار خطة أسعارك",
      desc: "انتهت الفترة التجريبية",
    },
    upgrade: {
      title: "مستعد لفعل المزيد مع زامت!",
      desc: "هناك الكثير من الميزات الرائعة في انتظارك. قم بترقية خطتك الآن للاستمتاع بهذه الميزات الجديدة.",
    },
    select_plan_sentence: "بس عندنا خطط أسعار مناسبة للاختيار منها",
    simple_plan: "خطط بسيطة. أسعار بسيطة. ادفع فقط مقابل ما تحتاجه حقًا",
    customer_service: "تأتي جميع الخطط مع دعم عملاء حائز على جوائز على مدار الساعة وطوال أيام الأسبوع",
    change_plans: "تغيير أو إلغاء خطة أسعارك في أي وقت",
    btn: "اكتشف الخطط المتاحة",
    support: "هل تحتاج إلى دعم في اختيار خطة أسعارك؟",
    send_email: "كلمنا على البريد الالكتروني",
  },
  cash_on_delivery: {
    thank_you: "شكرا لاختيارك زامت",
    courier: "سيتصل بك مندوب الشحن لتحديد موعد الاستلام.",
  },
  payment_successful: {
    title: "تم الدفع بنجاح",
    thank_you: "شكرا لاختيارك زامت، اكتشف المزيد الآن.",
  },
  gift_order: {
    gift_alert: "هذا الطلب هديه",
  },
  errors: {
    title: {
      multiple: "تم اكتشاف {num} من الأخطاء",
      single: "تم اكتشاف خطأ",
    },
    required: "هذه الخانة مطلوبة!",
    auth: {
      check_email: "لا يمكن الوصول إلى خوادمنا ، يرجى الانتظار",
      enter_otp: "otp خاطئ",
      request_otp: "تعذر إرسال OTP ، يمكنك المحاولة مرة أخرى",
      wrong_password: "بريد إلكتروني أو كلمة سر خاطئة",
      sign_up: "تعذر إنشاء حسابك",
      edit_info: "تعذر تحرير حسابك",
      forgot_password: "لا يمكن إرسال بريد إلكتروني",
      update_password: "تعذر تحديث كلمة السر",
      re_login: "يجب عليك تسجيل الدخول لأداء هذا الإجراء",
    },
    orders: {
      get_orders: "تعذر جلب الطلبات",
      get_draft_orders: "تعذر جلب الطلبات الغير مكتملة",
      get_order: "تعذر جلب البيانات لهذا المنتج",
      send_to_courier: "فشل إرسال الطلب للشحن",
      get_voo_couriers: "فشل جلب وسائل شحن VOO",
      update_order: "فشل تعديل الطلب",
      cancel_order: "فشل إلغاء هذا الطلب",
      delete_order: "فشل حذف هذا الطلب",
      export: "لا يمكن تصدير الطلبات",
      airway_bill: "تعذر جلب فاتورة هذا المنتج",
      e_invoice: "تعذر جلب فاتورة هذا المنتج",
      create_draft_order: "تعذر انشاء طلب غير مكتمل",
      save_order: "تعذر تفعيل هذا الطلب",
      customer_address: "هذا الزبون ليس لديه عنوان",
      bulk_update_orders: "تعذر تحديث الطلبات بشكل مجمّع",
      bulk_airway_bill: "تعذر جلب فواتير الشحن بشكل مجمّع",
      update_allow_customer_to_open: "تعذر تحديث السماح للعميل بفتح الطلب",
    },
    products: {
      add_product: "لا يمكن إضافة المنتج",
      metadata: "فشل جلب البيانات الوصفية",
      edit_product: "لا يمكن تعديل هذا المنتج",
      duplicate: "تعذر تكرار المنتج",
      delete_product: "فشل مسح هذا المنتج",
      get_product: "تعذر جلب البيانات لهذا المنتج",
      export: "لا يمكن تصدير المنتجات",
      import: "تعذر استيراد المنتجات",
      instagram: "تعذر جلب البيانات من Instagram",
      image: "تم تجاوز حد الصورة!",
      image_size: "يجب أن يكون حجم الصورة بحد أقصى 10 ميغا بايت!",
      delete_products: "فشل مسح المنتجات المحددة",
      update_review: "تعذر اخفاء التقييم ",
      get_reviews: "تعذر جلب التقييمات ",
    },
    discounts: {
      add_discount: "لا يمكن إضافة الخصم",
      metadata: "فشل جلب البيانات الوصفية",
      edit_discount: "لا يمكن تحرير هذا الخصم",
      duplicate: "تعذر تكرار الخصم",
      delete_discount: "فشل حذف هذا الخصم",
      get_discount: "تعذر جلب البيانات لهذا الخصم",
      export: "لا يمكن تصدير الخصومات",
    },
    staff: {
      add_staff: "لا يمكن إضافة عامل",
      remove_member: "تعذر حذف هذا العامل",
      get_profile: "تعذر جلب البيانات لهذا العامل",
      get_staff_members: "تعذر جلب بيانات طاقم العمل",
      update_permissions: "فشل في تحديث أذونات المستخدم",
      update_user_information: "تعذر تحديث بيانات هذا المستخدم",
      get_roles: "لا يمكن جلب الأدوار",
      get_permissions: "لا يمكن جلب الأذونات",
    },
    onboarding: {
      create_store: "عذرا ، لم نتمكن من إنشاء متجرك",
      subdomain_taken: "هذا المجال الفرعي مأخوذ",
      invalid_subdomain: "المجال الفرعي غير صالح",
      industries_fetch: "تعذر جلب الصناعات ، يرجى تحديث الصفحة",
    },
    collections: {
      add_collection: "تعذر إضافة المجموعة",
      edit_collection: "تعذر تحرير هذه المجموعة",
      delete_collection: "فشل حذف هذه المجموعة",
      get_collection: "تعذر جلب البيانات لهذه المجموعة",
      delete_product: "تعذر حذف هذا المنتج",
      add_products: "لا يمكن إضافة المنتجات إلى المجموعة",
    },
    plans: {
      get_plans: "تعذر إحضار خطط Zammit. يرجى الاتصال بالدعم",
    },
    analytics: {
      get_data: "حدث خطأ أثناء جلب البيانات",
      empty: "لا توجد بيانات لعرض",
    },
    customers: {
      export: "لا يمكن تصدير العملاء",
      add_customer_address: "لا يمكن إضافة عنوان",
      mark_address_default: "لا يمكن تحديد العنوان كـ افتراضي",
    },
    general_error: {
      title: "واجهنا خطأ في تحميل هذه الصفحة",
      subtitle: "هناك خطأ ما. إذا لم يتم إصلاحه في بضع دقائق ، يرجى الاتصال بالدعم.",
    },
    navigation: {
      get: "تعذر إحضار عناصر التنقل",
      update: "تعذر تحديث عنصر (عناصر) التنقل",
    },
    variants: {
      get_variants: "تعذر احضار المتغيرات",
      update_variant: "تعذر تعديل هذا المتغير",
      over_limit:
        "تجاوز الحد الأقصى للتصدير. يرجى ملاحظة أن الحد الأقصى للتصدير هو 15,000 عنصر. إذا كنت تحتاج إلى تصدير أكبر من ذلك، يرجى الاتصال بفريق الدعم لدينا للحصول على المساعدة. شكرًا لك.",
    },
    settings: {
      update_settings: "تعذر تحديث إعدادات الشركة",
      update_user_settings: "تعذر تحديث إعدادات المستخدم",
      delete_social_media_platforms: "تعذر حذف منصة التواصل الاجتماعي",
    },
    related_products: {
      update_related_products: "فشل تحديث المنتجات ذات الصلة",
      get_available_products: "فشل إحضار المنتجات",
    },
    products_reviews: {
      get_reviews: "تعذر جلب تقييم المنتجات",
    },
    own_locations: {
      get_location: "تعذر جلب الموقع",
      add_location: "تعذر إضافة الموقع",
      edit_location: "تعذر تحرير هذا الموقع",
    },
  },
  store_creation: {
    store_info_header: "اسم متجر رائع ،",
    store_info_subheader: "سوف تشكرك نفسك في المستقبل على اتخاذ القرار الصحيح",
    store_info_1_title: "أخبرنا المزيد عن عملك",
    store_info_1_industry_question: "ما هي صناعتك؟",
    store_info_1_subindustry_question: "ما هو تخصصك؟",
    store_info_2_title: "أضف عنوان عملك.",
    dina_dash_founder: "دينا داش، مؤسسة",
    dina_dash_quote:
      '"لقد استمتعت حقًا بالعمل مع Zammit ، فالموقع الإلكتروني سهل الاستخدام للغاية والعمل من خلاله. الفريق متعاون للغاية ويهتم للغاية بالتفاصيل. أوصي بالتسجيل في الشركات الناشئة والأعمال التجارية وجعل حياتهم أسهل."',
    store_creation_title: "أدخل معلومات الشركة والاتصال بك",
    store_creation_subtitle:
      "قل وداعًا لإدارة المخزون والمدفوعات عبر الإنترنت من خلال Excel! متجرك الإلكتروني على بعد دقائق.",
    store_name: "اسم المتجر",
    store_url: "رابط المتجر",
    store_url_hint: "اكتب اسم المتجر بالانجليزية وبدون مسافات",
    contact_name: "اسم جهة الاتصال",
    contact_email: "البريد الإلكتروني لجهة الاتصال (اختياري)",
    create_my_store: "إنشاء موقعي الالكتروني",
    launch: "هيا بنا نبدأ",
    loading: "{title} انتظر ، نحن ننشئ متجرك",
    welcome_onboard_1: "{store} تم إنشاء المتجر بنجاح.",
    welcome_onboard_2: "!مرحبا بك في متجرك الجديد.",
    go_my_store: "اذهب الى متجري",
  },
  address: {
    address_line: "تفاصيل العنوان",
    building: "المبنى",
    floor: "الدور",
    apartment: "الشقة",
    city: "المحافظة",
    region: "المنطقة",
    district: "الحي",
    country: "البلد",
    postalCode: "الرمز بريدي",
  },
  dashboard: {
    notifications_popup: {
      title: "ابق على اطلاع واحصل على تحديثات فورية بشأن أحدث طلباتك من خلال تمكين إشعارات المتصفح",
      btn: "إدارة الإشعارات",
    },
    home: "الرئيسية",
    orders: "الطلبات",
    draft_orders: "طلبات غير مكتملة",
    products: "المنتجات",
    customers: "العملاء",
    analytics: "التقارير",
    online_analytics: "المتجر الإلكتروني",
    zam_pos_analytics: "ZamPOS",
    discounts: "الخصومات",
    website_builder: "بناء الموقع",
    marketing: "التسويق",
    navigation: "التنقل",
    staff: "ادارة المستخدمين",
    settings: "الإعدادات",
    visit_store: "زُر المتجر",
    partners: "الشركاء",
    inventory: "المخزون",
    pricing: "التسعير",
    collections: "المجموعات",
    vendors: "البائعين",
    subscription: "خطة أسعارك",
    order_search_placeholder: "ابحث باستخدام رقم الطلب، العميل أو أسماء المنتجات",
    product_search_placeholder: "ابحث عن طريق اسم المنتج أو SKU أو الرمز الشريطي",
    filters: "التصفية",
    sort: "رتب حسب",
    select: "اختر",
    reset: "اعادة تعيين",
    done: "تم",
    cancel: "إلغاء",
    discard: "تجاهل",
    add: "اضافة",
    export: "تصدير",
    import: "استيراد",
    import_instagram_products: "استيراد منتجات Instagram (تجريبي)",
    clear: "حذف",
    apply: "وضع",
    more_options: "المزيد من الخيارات",
    items_selected: "العناصر المحددة",
    edit_selected: "تعديل المحدد",
    clear_filters: "امسح كل عوامل التصفية",
    free_trial: "خطة تجريبية مجانية",
    logout: "تسجيل الخروج",
    send_sms: "أرسل لعملاءك",
    borrow_and_grow: "استلف واكبر",
    paymob_self_onboarding: "استقبل مدفوعات بواسطة Paymob",
    "e-tax": "E-Tax",
    related_products: "منتجات ذات صله",
    unselect_all: "إلغاء تحديد الكل",
    select_all: "اختر الكل",
    plan: "الخطة {name}",
    demo: "متجر تجريبي",
    products_reviews: "تقييم المنتجات",
  },
  orders: {
    all_orders: {
      title: "قائمة الطلبات",
      only_new: "اظهر الطلبات الجديدة فقط",
      shipping_by: "الشحن بواسطة",
      actions: {
        ready_for_pickup: "حدده كـ جاهز للتسليم",
        on_route: "حدده كـ في الطريق",
        delivered: "حدده كـ تم توصيله",
        delivery_failed: "حدد كـ فشل توصيله",
        package_returned: "حدد كـ تم إرجاعه",
        paid_confirmation: "هل أنت متأكد أنك تريد تحديد هذا الطلب على أنه مدفوع؟",
        accept_paid_confirmation: "نعم تم الدفع",
        paid: "حدده كـ مدفوع",
        allow_customer_to_open: "السماح للعميل بفتح الطلب",
        airway_bill: "اطبع فاتورة الشحن",
        e_invoice: "إنشاء الفاتورة الإلكترونية",
        cancel: "إلغي الطلب",
        manage_order_on_own: "إدارة الطلب بنفسي ",
      },
      couriers: {
        title: "اختار وسيلة الشحن",
        btn: "اختر",
      },
      bulk_update_success: "تم تحديث الطلبات بنجاح",
      bulk_airway_bill_success: "تم تنزيل فواتير الشحن بنجاح",
      submit_multiple_shipments_success: "تم ارسال الطلبات لشركة الشحن بنجاح",
    },
    draft_orders: {
      title: "قائمة الطلبات الغير مكتملة",
      actions: {
        cancel: "حذف الطلب",
      },
    },
    orders_list: {
      table_headers: {
        order_id: "رقم الطلب",
        placed_on: "تاريخ الإضافة",
        updated_at: "تاريخ التعديل",
        customer: "العميل",
        value: "القيمة",
        channel: "قناة البيع",
        fulfillment_status: "حالة شركة التوصيل",
        payment_status: "حالة الدفع",
        payment_method: "طريقة الدفع",
        shipping: "الشحن",
      },
      channel: {
        online_store: "المتجر الإلكتروني",
        point_of_sale: "ZamPOS",
        dashboard: "Dashboard",
      },
      payment: {
        status: {
          unpaid: "غير مدفوع",
          paid: "مدفوع",
          refunded: "معاد ثمنه",
        },
        method: {
          cash_on_delivery: "الدفع عند الاستلام",
          zampos_cash_on_delivery: "كاش",
          online: "أونلاين كارد Paymob",
          geidea: "أونلاين كارد Geidea",
          gccpay: "أونلاين كارد Gccpay",
          stripe: "أونلاين كارد Stripe",
          paytabs: "أونلاين كارد PayTabs",
          valu: "ValU",
          card_on_delivery: "البطاقة عند التسليم",
          zampos_card_on_delivery: "البطاقة",
          sympl: "Sympl",
          bank_installments: "أقساط بنكية",
          mobile_wallets: "محفظة إلكترونية",
          bank_transfer: "تحويل بنكي",
          souhoola: "سهولة",
          tabby: "Tabby",
          forsa: "فرصة",
        },
      },
      couriers: {
        own: "On your own",
        r2s: "R2S",
        beyond: "Beyond",
        hybrid: "Hybrid",
        picks: "Picks",
        mylerz: "Mylerz",
        voo: "VOO",
        loadbugs: "Loadbugs",
        bosta: "Bosta",
        khazenly: "Khazenly",
        eyedel: "Eyedel",
      },
      sort: {
        id: "رقم الطلب",
        placed_on_new: "الأحدث للأقدم",
        placed_on_old: "الأقدم للأحدث",
        updated_at_new: "الأحدث للأقدم",
        updated_at_old: "الأقدم للأحدث",
        customer_name: "اسم العميل",
        payment_status: "حالة الدفع",
        fulfillment_status: "حالة شركة التوصيل",
        value_low: "القيمة (من الأقل للأعلى)",
        value_high: "القيمة (من الأعلى للأقل)",
      },
      filters: {
        status: {
          title: "حالة الطلب",
          values: {
            draft: "مسودة",
            active: "نشط",
            canceled: "ملغي من التاجر",
            archived: "مؤرشف",
          },
        },
        fulfillment_status: {
          title: "حالة شركة التوصيل",
          values: {
            unfulfilled: "طلب جديد",
            on_route: "في الطريق",
            delivered: "وصل",
            cancelled: "اتلغى من شركة التوصيل",
            delivery_failed: "فشل في التوصيل",
            at_warehouse: "في المخزن",
            package_returned: "رجع للمتجر",
            ready_for_pickup: "جاهز للتسليم",
            delayed: "اتأجل",
          },
        },
        payment_status: "حالة الدفع",
        payment_method: "طريقة الدفع",
        vendors: "البائعين",
        shipping: {
          title: "الشحن",
          values: {
            own: "عن طريقك",
            r2s: "R2S",
            beyond: "Beyond",
            hybrid: "Hybrid",
            picks: "Picks",
            mylerz: "Mylerz",
            voo: "VOO",
            loadbugs: "Loadbugs",
            bosta: "Bosta",
          },
        },
        channel: "قناة البيع",
        placed_on: {
          title: "تاريخ الطلب",
          placeholder: "اختر تاريخ أو مدة ...",
        },
        discount: "رمز ترويجي",
        no_data: "لم يتم العثور على طلبات تطابق بحثك",
        modify_search: "حاول تعديل معايير البحث الخاصة بك ",
      },
      actions: "الإجراءات",
      no_orders: "ليس لديك أي طلبات حتى الآن",
      no_draft_orders: "ليس لديك أي طلبات غير مكتملة حتى الآن",
      view_orders: "عرض جميع الطلبات",
    },
    order_details: {
      title: "طلب #{id}",
      placed_on: "بتاريخ",
      order_items: {
        title: "منتجات الطلب",
      },
      order_note: {
        title: "ملاحظات العميل",
      },
      order_customer: {
        title: "بيانات العميل",
      },
      order_recipient: {
        title: "بيانات المستلم",
      },
      order_seller: {
        title: "البائع",
      },
      order_address: {
        country: "البلد",
        city: "المحافظة",
        area: "المنطقة",
        district: "الحي",
        address_line: "تفاصيل العنوان",
        building: "المبنى",
        floor: "الدور",
        apartment: "الشقة",
        copy_location: "نسخ الموقع",
        get_directions: "تتبع الإتجاهات",
      },
      order_payment: {
        title: "الدفع",
        paymob_link: "افتح في Paymob",
        subtotal: "المبلغ",
        taxes: "الضرائب",
        cod_fee: "رسوم الدفع عند الاستلام",
        shipping_fees: "مصاريف الشحن",
        add_shipping: "اضف مصاريف الشحن",
        discount: "تخفيض",
        add_discount: "اضافة تخفيض",
        discount_amount: "مبلغ الخصم",
        total: "الإجمالي",
        apply: "تطبيق الخصم",
        cancel: "إلغاء",
        discount_title: "أدخل اسم الرمز الترويجي",
        use_discount: "استخدم رمز الترويجي",
        custom_discount: "رمز ترويجي مخصص",
        promo_code: "تم استخدام الرمز الترويجي",
        get_discount: "تعذر جلب البيانات لهذا الخصم",
        invalid_discount: "خصم غير صالح",
        invalid_discountValue: "قيمة الخصم لا يمكن ان تكون صفر",
      },
      allow_customer_to_open: "اسمح للعميل بفتح الطلب",
      order_shipping: {
        title: "الشحن",
      },
      view_file: "انقر هنا لعرض الملف",
      order_logs: {
        title: "سجل الطلبات",
        created: "تم إنشاء الطلب",
        by: "من قبل",
        staff: "موظف",
        statuses: {
          paid: "تم دفع الطلب",
          on_route: " الطلب في الطريق",
          cancelled: " تم إلغاء الطلب",
          delivered: "تم توصيل الطلب",
          package_returned: "تم إرجاع الطلب",
          at_warehouse: "الطلب في المخزن",
          ready_for_pickup: "الطلب جاهز للتسليم",
          delivery_failed: " فشل في توصيل الطلب",
        },
        by: "بواسطة",
        courier: "شركة الشحن",
      },
    },
    new_order: {
      browse_customers: {
        title: "العميل",
        search_placeholder: "ابحث عن العملاء",
        add_new: "اضف جديد",
      },
      browse_products: {
        title: "منتجات",
        search_placeholder: "ابحث عن طريق اسم المنتج أو SKU أو الرمز الشريطي",
      },
      products_list: {
        quantity_range: "الكمية الادني {min} و الكمية العظمة {max}",
      },
      mark_paid: {
        title: "حدده كـ مدفوع",
        body: "هل انت متاكد من تحديد هذا الطلب مدفوع؟",
        confirm: "نعم",
      },
      leave_page: {
        title: "غادر الصفحة",
        body: "لن يتم حفظ جميع التغييرات التي تم إجراؤها",
        unsaved_changes: "إذا غادرت هذه الصفحة ، فستفقد أي تغييرات غير محفوظة.",
        continue_editing: "الاستمرار في التعديل",
      },
      notes: {
        title: "ملحوظات",
        add_note: "اضف ملاحظة",
      },
      delivery: {
        title: "توصيل",
        require_delivery: "هل هذا الطلب يتطلب التوصيل؟",
        confirm_delivery: "نعم ، أريد تسليم هذا الطلب",
        discard_delivery: "لا ، لا أريد تسليم هذا الطلب",
      },
      shipping: {
        add: "أضف الشحن",
        cancel: "إلغاء",
        apply: "تطبيق الشحن",
        select: "حدد خيار واحد",
        standard: "شحن قياسي",
        free: "الشحن مجانا",
        custom: "المخصص",
        custom_shipping: "سعر الشحن المخصص",
        custom_price_error: "لا يمكن أن يكون السعر المخصص 0 أو أقل",
      },
    },
  },
  products: {
    modules: {
      media: "وسائط",
      basicInfo: "بيانات أساسية",
      pricing: "التسعير",
      inventory: "المخزون",
      variants: "المتغيرات",
      categorization: "التصنيف",
      shipping: "الشحن",
      extras: "إضافات",
      status: "حالة المنتج",
    },
    add_product: {
      media: {
        header: "وسائط",
        add_media: "أضف الوسائط",
        drag_upload: "أو اسحب الملفات للتحميل",
        type_and_size: "يقبل JPEG و PNG - بحد أقصى (10 ميجا بايت)",
        thumbnail: "صورة مصغرة",
        delete_modal: {
          title: "هل تريد حذف ملف وسائط واحد؟",
          body: "هل أنت متأكد أنك تريد حذف ملف الوسائط هذا؟ لا يمكن التراجع عن هذا الإجراء",
          cancel: "إلغاء",
          confirm: "نعم ، احذف",
        },
      },
      basic_info: {
        header: "بيانات أساسية",
        title: "لقب",
        description: "وصف",
        show_source: "إظهار المصدر الفعلي",
        url: "URL",
        ribbon: {
          label: "وسم",
          tooltip: "أضف تصنيفًا نصيًا مثل ”جديد” لتمييز هذا المنتج. سيتم عرضه على بطاقة المنتج الخاصة بك في المتجر.",
        },
        tooltip: "مهلا! يمكنك دائمًا إدخال معلوماتك باللغتين. لا تقلق ، سنقوم بتخزين كلا المعلومات",
      },
      search_engine_listing: { title: "قائمة محرك البحث" },
      variants: {
        deprecated: {
          using_features: "استخدام الميزات المتوقفة",
          message:
            "{povs} لها رسوم إضافية مرتبطة. سيؤدي تحرير هذا المنتج إلى إزالة هذه الرسوم نظرًا لإيقاف هذه الميزة. استخدم متغيرات السعر بدلا من ذلك.",
          modal: {
            title: "تغيير المنتج",
            subtitle: "هل أنت متأكد أنك تريد إجراء تغييرات على هذا المنتج؟",
            text: "يرجى ملاحظة أن هذا المنتج به خيار رسوم إضافية ستحتاج إلى إعادة إدخاله يدويًا إذا أجريت أي تغييرات",
            confirm: "نعم ، قم بإجراء التغييرات",
          },
        },
        duplicate: "مكرر",
        enter_hint: "الرجاء الضغط على إدخال لإضافة قيمة",
        header: "المتغيرات",
        toggle_title: "هذا المنتج له متغيرات",
        toggle_subtitle: "بيع إصدارات مختلفة من نفس المنتج. على سبيل المثال ، يأتي القميص بألوان أو أحجام متعددة.",
        add: "أضف المتغيرات",
        variants: "المتغيرات",
        variant: "البديل",
        options: "خيارات",
        options_title: "أضف خيارات المنتج الخاص بك",
        new_option: "خيار جديد",
        variants_title: "معلومات المتغيرات",
        price: "سعر",
        sku: "SKU",
        quantity: "الكمية",
        max_reached: "تم بلوغ الحد الأقصى لعدد المتغيرات ({num})",
        max_reached_warning:
          "ستؤثر إضافة أكثر من {num} من المتغيرات على أداء متجرك بشكل كبير. نحن لا نوصي بهذا. بدلاً من ذلك، يمكنك تجربة استخدام ميزة الإضافات لدينا لإضافة المزيد من الخيارات إلى منتجك.",
        delete_modal: {
          title: "قم بإزالة الخيارات المتغيرة",
          text: "سيؤدي حذف الخيار المحدد إلى حذف ما يلي",
          cancel: "إلغاء",
          confirm: "نعم ، احذف",
        },
      },
      extras: {
        header: "قم بتخصيص منتجك",
        toggle_title: "يحتوي هذا المنتج على إضافات يمكن إضافتها إليه",
        secondary_header:
          "قم ببيع الإضافات المختلفة أعلى نفس المنتج أو جمع معلومات مخصصة عندما يضيف العملاء هذا المنتج إلى سلة التسوق الخاصة بهم" +
          "\nمثلا: (كعكة مع صلصة شوكولاتة إضافية ، الاسم محفور على حرفة خشبية ، تاريخ الحجز لرحلة نهرية ، إلخ ...)",
        field_type: "نوع الحقل",
        view_type: "نوع العرض",
        error_min: "قيمة الحد الأدنى غير صالحة",
        error_max: "قيمة الحد الأقصى غير صالحة",
        field_label: "تسمية الميدان",
        min_options: "الحد الأدنى من الخيارات المحددة",
        max_options: "الحد الأقصى من الخيارات المحددة",
        min_chars: "الحد الأدنى الأحرف",
        max_chars: "الحد الأقصى الأحرف",
        new: "اضافي جديد",
        extra_fees: "رسوم إضافية؟",
        extra_fees_subtitle: "ضع علامة عليها إذا كنت تفرض رسومًا إضافية على سعر المنتج الخاص بك.",
        fixed_fees: "رسوم ثابتة",
        price_per_letter: "إدخال رسوم لكل حرف",
        required: "مطلوب",
        required_subtitle: "ضع علامة عليها إذا كان هذا الخيار مطلوبًا لمستخدمي متجرك للدفع.",
        extra_types: {
          datetime: "التاريخ / الوقت",
          dropdown: "اسقاط",
          text: "مكان كتابة",
          file: "رفع الملف",
        },
        extra_view_types: {
          multi_select: "قائمة منسدلة بخيارات متعددة",
          checkbox: "قائمة مربعات الاختيار",
        },
      },
      categorization: {
        header: "التصنيف",
        vendor: "بائع",
        type: "نوع",
        tags: "بطاقة شعار",
        dialog_label: "عرض كل العلامات",
        select_tags: "حدد العلامات",
        existing_tags: "العلامات الموجودة",
        selected_tags: "العلامات المختارة",
        tags_description: "العلامات المحددة من القائمة أعلاه ستظهر هنا",
        preview: "النوع والبائعين والعلامات",
        no_collections: "لا توجد مجموعات متاحة",
        collection: "مجموعة",
        google_product_category: "فئة المنتج",
      },
      pricing: {
        header: "التسعير",
        discounted: "سعر مخفض",
        sale: "خصم",
        vat: "فرض ضريبة القيمة المضافة على هذا المنتج",
        priced_by: {
          title: "نوع السعر",
          options: {
            unit: "وحدة",
            product_length: "الطول",
            area: "المساحة",
            weight: "الوزن",
          },
        },
      },
      inventory: {
        header: "المخزون",
        sold_out: "وضع علامة على المنتج كمباع",
        sku: "SKU",
        barCode: "الرمز الشريطي",
        unlimited_title: "مخزون غير محدود",
        unlimited_subtitle: "قم بتمكين هذا الخيار إذا كان لا ينبغي تتبع المخزون.",
        quantity: "الكمية المتوفرة",
        limit: "طلب قيود الكمية",
        min: "أقل كمية ممكن طلبها",
        max: "كمية الطلب القصوى",
      },
      shipping: {
        header: "الشحن",
        add_shipping_toggle_title: "هذا المنتج لديه مصاريف توصيل",
        is_physical_toggle_title: "هذا منتج مادي",
        weight: "وزن",
        unit: "وحدة",
        on_hint: "تستخدم لحساب أسعار الشحن عند الخروج وأسعار التسمية أثناء التنفيذ",
        off_hint: "لن يقوم عملاؤك بإدخال عنوان الشحن الخاص بهم أو اختيار طريقة الشحن عند شراء هذا المنتج",
        units: {
          kg: "كلغ",
          g: "ج",
        },
      },
      status: {
        header: "حالة المنتج",
        status: "الحالة",
        hint: "سيتم إخفاء هذا المنتج حتى تقوم بتحويله إلى نشط.",
        values: {
          hidden: "مختفي",
          visible: "نشيط",
          sold_out: "نفذ",
        },
      },
    },
    product_details: {
      set_active: "تعيين كنشط",
      set_draft: "تعيين كمسودة",
      duplicate: "مكرر",
      archive: "أرشيف",
      delete: "حذف",
      success_message: 'تمت إضافة "{title}"',
      add_product: "أضف منتجًا آخر",
      product_not_found: "الصنف غير موجود",
      delete_product: "حذف المنتج",
      archive_product: "أرشيف المنتج",
      duplicate_modal: {
        modal_title: "منتج مكرر",
        title: "عنوان",
        select_details: "حدد التفاصيل لنسخها إلى المنتج الجديد",
        copy: "ينسخ",
        duplicate: "ينسخ",
      },
      delete_modal: {
        title: "هل تريد حذف المنتج؟",
        body: "هل أنت متأكد أنك تريد حذف المنتج؟ لا يمكن التراجع عن هذا الإجراء",
        cancel: "إلغاء",
        confirm: "نعم ، احذف",
      },
      variants: {
        variants: "المتغيرات",
        num_variants: "{num} متغيرات",
        new_variant: "أضف متغيرًا جديدًا",
        manage_options: "إدارة الخيارات",
        manage_options_modal: {
          unsaved_changes: "لديك بعض التغييرات غير المحفوظة",
          text: "يجب عليك حفظ أو تجاهل التغييرات التي أجريتها على هذا المنتج من أجل إدارة خياراته",
        },
        number_variants: "{number} متغيرات",
        back_product: "العودة إلى المنتج",
        options: "خيارات",
        add_media: "أضف الوسائط",
        choose_existing: "أو اختر القائمة",
        select_variant_image: "حدد صورة متغيرة",
        select_variant_image_text: "اختر من الصور المتاحة أو تحميل واحدة جديدة",
      },
    },
    product_reviews_details: {
      close: "إغلاق",
      hide_all_reviews: "إخفاء جميع التقييمات",
      hide_all_reviews_hint: "تفعيل هذا الزر يخفي جميع التقييمات عن المنتج",
      reviews: "تقييمات",
      pagination: {
        prev: "السابق",
        next: "التالي",
      },
    },
    all_products: {
      sort: {
        title: "عنوان المنتج (أ-ي)",
        recent: "أضيف مؤخرا",
        price_low: "السعر من الارخص للاعلى",
        price_high: "السعر الاعلى الى الادنى ",
      },
      actions: {
        visible: "تعيين كنشط",
        hidden: "تعيين كمسودة",
        delete: "حذف",
        sold_out: "تعيين كنفذ",
        view_details: "عرض التفاصيل",
      },
      products_list: {
        title: "قائمة المنتجات",
        num_items: "({num} منتج)",
        products: "منتج(ات) ",
        in_stock: "في المخزن",
        variants: "المتغيرات",
        actions: "أجراءات",
        out_stock_message: "مهلا! لديك {num} منتج(ات) غير متوفرة",
        no_products: "ليس لديك أي منتجات حتى الآن",
        add_first: "أضف منتجك الأول",
        stock: {
          out_of_stock: "انتهى من المخزن",
          in_stock: "{num} في الأوراق المالية",
          for_variants: "لعدد {num} من المتغيرات",
        },
        dismiss: "صرف النظر",
        view_products: "مشاهدة المنتجات",
        table_headers: {
          product: "المنتج",
          status: "الحالة",
          stock: "مخزون",
          price: "سعر",
          type: "نوع",
          tags: "العلامات",
        },
        edit_products: { name: "تحرير {num} المنتج", description: "" },
        set_active: { name: "تعيين {word} كنشط", description: "" },
        set_draft: { name: "تعيين {word} كمسودة", description: "" },
        add_collection: { name: " أضف {word} إلى المجموعة", description: "" },
        remove_collection: { name: "إزالة {word} المجموعات", description: "" },
        duplicate_product: { name: "منتج {num} مكرر", description: "" },
        delete_product: { name: "حذف  {num}المنتج", description: "" },
      },
      filters: {
        stock_filter: "اعرض فقط المنتجات التي نفذت من المخزون",
        variants_filter: "اعرض فقط المنتجات التي تحتوي على متغيرات",
        collection: "المجموعة ",
        status: "الوضع هو ",
        type: "النوع ",
        is: "يكون",
        are: "نكون",
        and: "و",
        more: "أخرى",
        stock: "إنتهى من المخزن",
        variants: "له متغيرات",
        no_data: "لم يتم العثور على منتجات تطابق بحثك",
        modify_search: "حاول تعديل معايير البحث الخاصة بك أو ",
        add_product: "إنشاء منتج جديد",
        vendors: " البائعين",
      },
      products: {
        browse: "تصفح المنتجات",
        select_products: "اختر المنتجات",
        cancel: "يلغي",
        add_num: "يضيف ({num})",
        add_products: "أضف ({num}) منتجات",
        show_extras: "إظهار الإضافات",
        length_label: "ادخل الطول ({unit})",
        width_label: "ادخل العرض ({unit})",
        weight_label: "ادخل الوزن ({unit})",
      },
    },
    instagram: {
      select_posts: "حدد المنتجات للاستيراد",
      submit: "إرسال",
      select_all: "اختر الكل",
      deselect_all: "الغاء تحديد الكل",
    },
    products_reviews: {
      title: "تقييم المنتجات",
      no_reviews: "لم يتم العثور على تقييمات",
      table_headers: {
        product: "المنتج",
        reviews: "التقييمات",
      },
    },
  },
  collections: {
    sorting: {
      sort_by: "صنف حسب: ",
      manual: "يدوي",
      price_asc: "السعر من الارخص للاعلى",
      price_desc: "السعر الاعلى الى الادنى",
      name_asc: "الاسم (من الألف إلى الياء)",
      name_desc: "الاسم (من ي إلى أ)",
      created_at_asc: "تاريخ الانشاء (من الاقدم الي الاحدث)",
      created_at_desc: "تاريخ الانشاء (من الاحدث الي الاقدم)",
    },
    parameters: {
      id: "جميع المنتحات",
      name: "اسم",
      tags: "بطاقة شعار",
      discounted_price_cents: "سعر مخفض",
      is_on_sale: "يوجد تخفيض",
      price_cents: "سعر",
      type: "نوع",
      vendor: "بائع",
      quantity: "كمية",
    },
    values: {
      true: "نعم",
      false: "لا",
    },
    operators: {
      eq: "يساوي",
      matches: "يشمل",
      not_eq: "لا يساوي",
      does_not_match: "لا يشمل",
      gt: "أكثر من",
      lt: "أقل من",
      any: "يساوي",
    },
    all_collections: {
      plus_more: "+ {num} أكثر...",
      is: "يكون",
      title: "قائمة التجميعات",
      num_items: "({num} تجميعات)",
      search_placeholder: "إبحث عن طريق إسم التجميعة",
      search_placeholder_mobile: "إبحث",
      collection_type: "نوع التجميعة",
      products: "منتجات",
      sort: "رتب",
      filters: "تصفية",
      type: "النوع",
      manual: "يدوي",
      smart: "ذكي",
      smart_collection: "مجموعة ذكية",
      browse_all_collections: "تصفح جميع المجموعات",
      column_headers: {
        name: "الإسم",
        num_of_items: "عدد المنتجات",
        conditions: "الشروط",
        type: "النوع",
        total_related_products: "إجمالي المنتجات ذات الصلة",
      },
      sort: {
        recent: "أضيف مؤخرا",
        count_low: "العدد (من الأقل إلى الأعلى)",
        count_high: "العدد (من الأعلى إلى الأقل)",
      },
    },
    new_collection: {
      type: {
        header: "نوع المجموعة",
        manual: "اليدوية",
        manual_subtitle: "يدويا إضافة المنتجات إلى هذه المجموعة",
        smart: "ذكي",
        smart_subtitle: "صِف خصائص المنتج وسيتم تحديثها تلقائيًا",
      },
      products: {
        add: "أضف منتج",
        browse: "تصفح المنتجات",
        search_hint: "ابحث عن طريق اسم المنتج أو SKU أو الرمز الشريطي",
        cancel: "يلغي",
        add_num: "يضيف ({num})",
        add_products_num: "أضف منتج({num})",
        no_products: "لم تتم إضافة أي منتجات إلى هذه المجموعة حتى الآن ، ابدأ في إضافتها.",
        browse_all: "تصفح جميع المنتجات",
        preview_title: "منتجات",
        preview_subtitle: "{num} المنتج(ات) المضافة",
      },
      conditions: {
        preview_title: "شروط",
        preview_subtitle: "{num} تمت إضافة الشرط",
        globalOperator: {
          title: "يجب أن تتطابق المنتجات",
          and: "كل الظروف",
          or: "أي شرط",
        },
        new: "شروط جديدة",
        parameter: "معامل",
        condition: "شرط",
        automatic_products: "المنتجات المضافة تلقائيًا",
        hint: "يرجى ملاحظة أنه تتم إضافة هذه المنتجات تلقائيًا بناءً على الشروط التي قمت بتعيينها مسبقًا ولا يمكن تحريرها أو حذفها.",
      },
    },
    browse_modal: {
      title: "تصفح المجموعات",
      search_hint: "البحث عن طريق اسم المجموعة",
      cancel: "إلغاء",
      add_num: "يضيف ({num})",
    },
  },
  inventory: {
    all_inventories: {
      title: "المخزون",
      product_search_placeholder: "ابحث عن طريق اسم المنتج أو SKU أو الرمز الشريطي",
      search_placeholder_mobile: "ابحث",
      update: "تحديث",
      go_products: "انتقل إلى المنتجات",
      stock: "متوفر",
      updated_stock: "سيكون المخزون",
      filters: "التصفية",
      export: "تصدير",
      column_headers: {
        product: "منتج",
        status: "حالة المنتج",
        stock: "مخزون",
        edit_quantity: "تعديل المخزون",
      },
      sort: {
        sort: "رتب حسب",
        recent: "حديث",
        quantity_low: "كمية منخفضة",
        quantity_high: "كمية عالية",
      },
    },
  },
  inventory: {
    title: "المخزون",
    product_search_placeholder: "ابحث عن طريق اسم المنتج أو SKU أو الرمز الشريطي",
    search_placeholder_mobile: "بحث",
    update: "تحديث",
    go_products: "انتقل إلى المنتجات",
    stock: "مخزون معد للبيع",
    updated_stock: "سيكون المخزون",
    column_headers: {
      product: "المنتج",
      status: "حالة",
      stock: "مخزون",
      edit_quantity: "تحرير الكمية",
    },
    sort: {
      sort: "رتب حسب",
      recent: "حديث",
      quantity_low: "الكمية (من الأقل إلى الأعلى)",
      quantity_high: "الكمية (من الأعلى إلى الأقل)",
    },
  },
  pricing: {
    title: "التسعير",
    product_search_placeholder: "ابحث عن طريق اسم المنتج أو SKU أو الرمز الشريطي",
    search_placeholder_mobile: "بحث",
    update: "تحديث",
    go_products: "انتقل إلى المنتجات",
    column_headers: {
      product: "المنتج",
      status: "حالة",
      edit_pricing: "تحرير التسعير",
    },
    sort: {
      sort: "رتب حسب",
      recent: "حديث",
      price_low: "السعر (من الأقل إلى الأعلى)",
      price_high: "السعر (من الأعلى إلى الأقل)",
      discounted_price_low: "سعر مخفض (من الأدنى إلى الأعلى)",
      discounted_price_high: "سعر مخفض (من الأعلى إلى الأقل)",
    },

    bulk_update: {
      title: "السماح بإضافة نسبة أو مبلغ بالجنيه المصري لجميع الأسعار",
      original_price: "أسعار أصلية",
      update_original_price: "تحديث الأسعار الأصلية",
      discounted_price: "أسعار مخفضة",
      update_discounted_price: "تحديث الأسعار المخفضة",
      apply_original_price: "تطبيق على الأسعار الأصلية",
      apply_discounted_price: "تطبيق على الأسعار المخفضة",
      update_products_prices: "تحديث أسعار المنتجات",
      recent: "الأحدث",
      price_low: "السعر (من الأدنى إلى الأعلى)",
      price_high: "السعر (من الأعلى إلى الأدنى)",
      discounted_price_low: "السعر المخفض (من الأدنى إلى الأعلى)",
      discounted_price_high: "السعر المخفض (من الأعلى إلى الأدنى)",
      adjustment_type: {
        increase: "زيادة",
        decrease: "خفض",
      },
    },
  },
  discounts: {
    modules: {
      basicInfo: "بيانات أساسية",
      value: "القيمة",
      conditions: "الشروط",
      variants: "المتغيرات",
      usage: "الاستخدام",
      buyXGetYConditions: "اشترِ X احصل على Y الشروط",
    },
    add_discount: {
      basic_info: {
        header: "بيانات أساسية",
        code_name: "اسم الرمز الترويجي",
        tooltip: "سيدخل العملاء هذا الاسم عند الدفع",
        discount_method: [
          "تطبيق الخصم عند ادخال كود الخصم عندما تكون الشروط صحيحة",
          "تطبيق الخصم تلقائيًا عندما تكون الشروط صحيحة",
        ],
      },
      type: {
        header: "نوع",
        ProductsDiscount: "مبلغ من المنتجات",
        BillDiscount: "مبلغ من الطلب",
        BuyXGetYDiscount: "اشتر X احصل على Y",
        ShippingDiscount: "مبلغ من الشحن",
      },
      value: {
        header: "القيمة",
        discount_value: "قيمة الخصم",
        fixed_amount: "ثابت",
        percentage: "نسبة مئوية",
        free_shipping: "الشحن مجانا",
      },
      conditions: {
        header: "الشروط",
        none: "لا يوجد",
        minPurchase: "الحد الأدنى للشراء",
        minProductQuantity: "الحد الأدنى لكمية المنتج",
        is_x_exact_match: "يجب على العميل شراء جميع العناصر المحددة للخصم لتطبيقها",
        is_y_exact_match: "يجب أن يكون لدى العميل جميع العناصر المحددة في عملية الشراء",
        set_x_match: "كم مرة يجب على العميل شراء جميع العناصر في قائمة مشتريات العميل من أجل تطبيق هذا الخصم",
        unset_x_match: "ما هو الحد الأدنى من المنتجات التي يجب شراؤها لتطبيق هذا الخصم",
        max_y_quantity: " ما هو الحد الأقصى من العناصر التي يحصل عليها العميل",
      },
      usage: {
        header: "الاستخدام",
        customers: {
          title: "من يمكنه استخدامه؟",
          everyone: "الجميع",
          specific_customers: "عملاء محددين",
        },
        limits: {
          title: "حدود استخدام؟",
          max_uses_label: "حد الاستخدام الإجمالي",
          max_uses: "يحتوي الرمز على حد إجمالي للاستخدام عبر المتجر بأكمله ",
          once_per_customer: "العميل مقيد باسترداد الرمز مرة واحدة فقط",
          only_signed_in_customers: "السماح للعملاء المسجلين فقط",
        },
      },
      dates: {
        header: "التواريخ",
        startDate: "تاريخ البدء",
        endDate: "تاريخ الانتهاء",
        setEndDate: "حدد تاريخ الانتهاء",
        selectStartDate: "حدد تاريخ البدء",
        selectEndDate: "حدد تاريخ الانتهاء",
      },
      errors: {
        code_error: "يجب أن يتكون الرمز الترويجي من كلمة واحدة فقط بدون مسافات",
      },
      select: "اختار",
      select_from: "محدد من",
      select_items: "من  المنتجات التالية",
      apply_items: "ينطبق فقط على هذه المنتجات",
      CustomerBuys: "يشتري العميل",
      CustomerGets: "يحصل العميل",
      types: {
        specific_products: "منتجات محددة",
        specific_collections: "مجموعات محددة",
        everything: "كل شئ",
        Product: "منتج",
        Collection: "مجموعة",
      },
      example: "حاول تغيير الرقم لرؤية أمثلة سريعة الاستجابة",
      clarification: {
        title: "توضيح",
        set_exact_match_description:
          "افترض أن العناصر المحددة في قائمة مشتريات العميل عبارة عن حقيبة وحذاء. إن تحديد مربع الاختيار أعلاه وتعيين حقل النص على {num} يعني أن: -",
        unset_exact_match_description:
          "ومع ذلك ، إذا لم تحدد خانة الاختيار وقمت بتعيين حقل النص على {num} فهذا يعني: -",
        exact_match_description: "سيتعين على العملاء شراء {num} من كل منتج",
        exact_match_example: "مثال {num} حقيبة (حقائب) و {num} جورب (جورب)",
        inexact_match_description: "سيضطر العملاء إلى شراء إجمالي {num} منتج (منتجات)",
        inexact_match_example: " مثال: {firstVal} حقيبة (حقائب) و {secondVal} جورب",
        inexact_match_second_example: "مثال: {num} حقيبة (حقائب)",
        inexact_match_third_example: "مثال: {num} الجوارب",
      },
    },
    all_discounts: {
      title: "الخصومات",
      discount_search_placeholder: "البحث عن طريق اسم الرمز الترويجي",
      filters: "التصفية",
      sort: "رتب حسب",
      select: "اختر",
      export: "تصدير",
      add_promo_button: "أضف الرمز الترويجي",
      promo_code: "الرموز الترويجية",
      automatic_discounts: "خصومات تلقائية",
      column_headers: {
        code: "اسم الرمز",
        details: "تفاصيل",
        conditions: "الشروط",
        usage: "الاستخدام ",
        status: "الحالة",
        date: "التاريخ",
        count: "عدد الاستخدام",
      },
      actions: {
        title: "إجراءات",
        visible: "تفعيل أكواد الخصم",
        hidden: "تعطيل أكواد الخصم ",
        delete: "حذف رموز الخصم",
      },
      no_promo_codes: "ليس لديك أي رموز ترويجية تم إنشاؤها حتى الآن",
      add_promo_codes: "ابدأ بإضافة رمزك الترويجي الأول",
      details: {
        percentage: "{val} % خصم",
        fixed_amount: "{val} خصم",
        BillDiscount: "طلب",
        ShippingDiscount: "شحن",
        BuyXGetYDiscount: "اشتري X احصل على Y",
        ProductsDiscount: "لكل من {buyNum} منتجات",
        automatic: "خصم تلقائي",
        manual: " خصم عند اضافة كود الخصم",
      },
      conditions: {
        no_min_req: "لا يوجد حد أدنى",
        min_req: "{val} حد أدنى",
        max_uses: "يقتصر على {val} الاستخدامات",
        unlimited: "استخدامات غير محدودة",
        only_signed_in_customer: "العملاء الذين قاموا بتسجيل الدخول فقط",
        specific_customers: "العملاء المسموح لهم بـ {val}",
        unlimited_customers: "مسموح للجميع",
        once_per_customer: "يسمح باستخدام واحد لكل عميل",
        all_products: "صالح على جميع المنتجات",
        specific_collections: "صالح على مجموعات {val}",
        specific_products: "صالح على منتجات {val}",
      },
      dates: {
        from: "من { date }",
        select_start_date: "حدد تاريخ البدء (yyyy/mm/dd)",
        select_end_date: "حدد تاريخ الانتهاء (yyyy/mm/dd)",
        invalid: "تاريخ غير صالح!",
      },
    },
    discount_details: {
      set_active: "تعيين كنشط",
      set_inactive: "تعيين غير نشط",
      delete: "حذف",
      success_message: 'تمت إضافة "{title}"',
      add_discount: "أضف خصمًا آخر",
      delete_discount: "Delete discount",
      delete_modal: {
        title: "هل تريد حذف الخصم",
        body: "هل أنت متأكد أنك تريد حذف الخصم؟ لا يمكن التراجع عن هذا الإجراء",
        cancel: "إلغاء",
        confirm: "نعم ، احذف",
      },
    },
  },
  related_products: {
    title: "منتجات ذات صله",
    select: "حدد المنتجات ذات الصلة",
    selected_related_products: "منتجات ذات صلة مختارة",
    add_more_products: "أضف المزيد من المنتجات",
    add_products: "أضف المنتجات",
    product_search_placeholder: "ابحث عن طريق اسم المنتج أو SKU أو الرمز الشريطي",
    search_placeholder_mobile: "بحث",
    column_headers: {
      product: "منتج",
      price: "سعر",
      option: "خيار",
      total_related_products: "إجمالي المنتجات ذات الصلة",
    },
    show_selected: "اظهار المحدد في هذه الصفحة",
    cancel: "إلغاء",
    apply: "طبق",
    related_products_count: "{num} المنتجات ذات الصلة ",
    no_products: "ليس لديك أي منتجات ذات صلة حتى الآن",
    add_more_products: "أضف المزيد من المنتجات",
  },
  navigation: {
    title: "عناصر التنقل",
    status: {
      active: "نشيط",
      hidden: "مختفي",
    },
    edit_label: "تحرير التسمية",
    label_name: "اسم الطابع",
  },
  staff_management: {
    store_owner: "مالك المتجر",
    staff: "طاقم العمل",
    add_staff_btn: "أضف عامل",
    edit_staff_title: "تحرير الموظف",
    save: "حفظ",
    cancel: "إلغاء",
    not_logged_yet: "لم يتم تسجيل الدخول بعد",
    store_profile: "الملف الشخصي",
    add_staff: {
      first_name: "الاسم الأول",
      last_name: "الاسم الأخير",
      phone_number: "رقم الهاتف",
      email: "البريد الإلكتروني",
    },
    edit_staff: "تعديل",
    delete_staff: "حذف",
    select_permissions: "حدد أذونات للموظفين الذين تدعوهم",
    edit_select_permissions: "تعديل أذونات الموظفين",
    select_role: "حدد دورًا للموظفين الذين تدعوهم",
    other: "آخر",
    assign_permissions: "تعيين الأذونات بشكل منفصل أدناه",
    modules: {
      collection: "مجموعة",
      product: "منتج",
      purchase: "طلب",
      analyticsIntegrations: "إعدادات التحليلات",
      discount: "تخفيض",
      navigation: "تنقلات",
      analytics: "تحليلات",
      customer: "عميل",
      websiteBuilder: "بناء الموقع",
    },
    select_all: "(اختر الكل)",
    table_headers: {
      name: "الاسم",
      email: "البريد الإلكتروني",
      phone_number: "رقم الهاتف",
      role: "الدور",
      last_login: "آخر تسجيل دخول",
      action: "عمل",
    },
  },
  pick_plan: {
    address: {
      amazing_choice: "اختيار رائع {name}، 👍",
      get_started: "سنساعدك على البدء في بضع خطوات سريعة",
      business_address: "أضف عنوان عملك",
      save: "حفظ العنوان",
    },
    title: "أنظمة مرنة مناسبة للجميع",
    subtitle: "سواء كنت قد بدأت للتو في البيع عبر الإنترنت أو أنك تتوسع في جميع أنحاء العالم ، فقد قمنا بتغطيتك.",
    choose_billing_frequency: "عايز تجديد الاشتراك يكون",
    choose_plan: "اختر باقتك",
    monthly: "شهرياً",
    quarterly: "ربع سنوياً",
    semi: "نصف سنوياً",
    annually: "سنوياً",
    biannually: "سنتان",
    bronze: "البرونزي",
    silver: "الفضي",
    gold: "الذهبي",
    zampos: "ZamPOS",
    subscribe: "إشتراك",
    most_popular: "الأكثر شهرة",
    exclude_vat: "باستثناء ضريبة  القيمة المضافة",
    save_percent: "وفر {value}٪",
    learn_more_about_plan_details: "إعرف أكثر عن أنظمة الأسعار",
    description: {
      bronze: "إذا كنت تبدأ عملك وتقوم ببناء موقع الويب الأول الخاص بك",
      silver: "الأكثر شيوعًا لنمو الأعمال التجارية التي كانت فكرة بسيطة في يوم من الأيام",
      gold: "الأفضل للشركات الصغيرة والمتوسطة التي تجري تحولًا رقميًا",
      zampos: "حل بسيط وسهل لإدارة مبيعاتك الأوفلاين",
    },
    frequency_months: {
      monthly: "",
      quarterly: "x ثلاث شهر",
      semi: "x ٦ شهر",
      annually: "x ١٢ شهر",
      biannually: "x سنتان",
    },
    offers: {
      ramadan: {
        offer: "عرض رمضان",
        buy2get1: "اشتري سنتين واحصل على السنة الثالثة مجانا. تطبق الشروط والأحكام",
        banner: "{num} أيام متبقية في عرض رمضان. سارع بالحصول على أقصى استفادة من زامت 🔥",
      },
    },
  },
  chips: {
    values: {
      hidden: "مسودة",
      visible: "نشط",
      sold_out: "مؤرشف",
      disabled: "معطل",
      expired: "منتهية الصلاحية",
      inactive: "غير نشط",
      active: "نشط",
      draft: "مسودة",
      unfulfilled: "جديد",
      on_route: "في الطريق",
      delivered: "وصل",
      cancelled: "اتلغى",
      delivery_failed: "فشل في التوصيل",
      at_warehouse: "في المخزن",
      package_returned: "رجع للمتجر",
      ready_for_pickup: "جاهز للتسليم",
      delayed: "اتأجل",
      unpaid: "غير مدفوع",
      paid: "مدفوع",
      refunded: "معاد ثمنه",
      pickup: "طلب استلام من المتجر",
      registered: "لديه حساب",
      guest: "ضيف",
      subscribed: "مشترك بالنشرة البريدية",
      not_subscribed: "غير مشترك بالنشرة البريدية",
    },
  },
  home: {
    notifications: {
      title: "الق نظرة!",
      back_home: "العودة إلى الصفحة الرئيسية",
      check_all: "تحقق من جميع تحديثاتنا",
      check_now: "تحقق الآن",
      dismiss: "تجاهل",
      refer: {
        title: "رشح أصدقاءك واكسب",
        subtitle: "لكل صديق ترشحه لاستخدام زامت ويبدأ اشتراكه في زامت، هتكسب شهر زيادة في اشتراكك",
        win: "اكسب",
      },
      zampos: {
        title: "بيع دون اتصال في متجرك أو بازار",
        subtitle:
          "إلى جانب موقع الويب الخاص بك ، استخدم تطبيق ZamPOS للبيع في وضع عدم الاتصال! قم بدعوة فريقك الآن وقم بالمزامنة من نفس المخزون. يتلقى العملاء الإيصالات الخاصة بك عن طريق الرسائل القصيرة",
        redirect: "تحميل التطبيق",
      },
      feedback2022: {
        title: "عايزين نعرف رأيك في زامت في ٢٠٢٢",
        subtitle:
          "كانت سنة مشوقة جداً وكنا متحمسين نبني أكتر في زامت ونكبر مع بعض، عايزين نسمع منكوا علشان نتأكد اننا ماشيين على الطريق الصح واحنا بنخطط ل ٢٠٢٣",
        cta: "من هنا",
      },
    },
    fresh_sign_ups: {
      welcome: "مرحبًا {name} ، مرحبًا بك في متجرك.",
      set_store: "قم بإعداد متجرك",
      set_store_subtitle: "احصل على متجرك وتشغيله في بضع خطوات بسيطة!",
      milestones: {
        hasCourier: "اختر كيفية شحن منتجاتك",
        hasCustomDomain: "ربط المجال الخاص بك",
        hasPaymentMethod: "أضف طريقة للحصول على أموال",
        hasProducts: "أضف منتجك الأول",
      },
      theming: {
        title: "صمم موقع الويب الخاص بك",
        subtitle: "السيطرة الكاملة على تصميم موقع الويب الخاص بك",
        customize: "تخصيص موضوعي",
      },
      trial_footer: "الأيام المتبقية في المحاكمة الخاصة بك. قم بالترقية لتحقيق أقصى استفادة من Zammit",
      select_plan: "حدد خطة",
    },
    active: {
      good_morning: "صباح الخير، {name} 👋",
      store_performance: "أداء متجري",
      all_reports: "مشاهدة جميع التقارير",
    },
    new_builder_popup: {
      title: "بناء الموقع الجديد",
      desc: "جعلناها سهلة بالنسبة لك! أبني موقعك الآن وقم بتخصيصه ليتناسب مع علامتك التجارية. نحن واثقون من أنك ستحب منشئ موقع الويب الجديد، ولا يمكننا الانتظار حتى تجربه!",
      btn: "ابدأ",
    },
  },
  charts: {
    last_n_days: "آخر {num} يوم",
    today: "اليوم",
    yesterday: "في الامس",
    custom: "مخصص",
    compare: "مقارنة ب {period}",
    prev_period: "من فترة السابق",
    week: "الاسبوع الماضي",
    month: "الشهر الماضي",
    quarter: "الربع الماضي",
    year: "السنة الماضية",
    all_time: "كل الوقت",
    sales: {
      title: "إجمالي المبيعات",
      returns: "عائدات",
      cancelled: "ألغيت",
      failed: "فشل",
    },
    sales_channels: {
      title: "قنوات البيع",
      types: {
        online_store: "المتجر الإلكتروني",
        point_of_sale: "ZamPOS",
        dashboard: "Dashboard",
      },
    },
    sales_people: {
      title: "عمال المبيعات",
    },
    order_count: {
      title: "إجمالي أرقام الطلبات",
      performance_status: "حالة الأداء {num}%",
      orders: "طلبات",
    },
    order_status: {
      title: "حالة الطلب",
      total_orders: "إجمالي الطلبات {num}",
    },
    order_average: {
      title: "متوسط ​​قيمة الطلب",
    },
    payment_methods: {
      title: "إجمالي المدفوعات والمكتملة",
      types: {
        all: {
          cash_on_delivery: "الدفع (عند الاستلام)",
          online: "متصل",
          valu: "Valu",
          card_on_delivery: "البطاقة (عند التسليم)",
          sympl: "Sympl",
          bank_installments: "أقساط بنكية",
          bank_transfer: "التحويل المصرفي",
        },
        online_store: {
          cash_on_delivery: "الدفع عند الاستلام",
          online: "متصل",
          valu: "Valu",
          card_on_delivery: "البطاقة عند التسليم",
          sympl: "Sympl",
          bank_installments: "أقساط بنكية",
          bank_transfer: "التحويل المصرفي",
        },
        point_of_sale: {
          cash_on_delivery: "الدفع",
          card_on_delivery: "البطاقة",
          bank_transfer: "التحويل المصرفي",
        },
      },
    },
    top_value: {
      title: "أهم المنتجات من حيث القيمة",
    },
    top_units: {
      title: "أعلى المنتجات حسب الوحدات المباعة",
    },
    returning_customers: {
      title: "معدل العائد للعميل",
    },
    list: {
      see_more: "شاهد المزيد",
      see_less: "رؤية أقل",
    },
  },
  customers: {
    all_customers: {
      no_customers: "ليس لديك أي عملاء حتى الآن",
      add_first_customer: "ابدأ في إضافة عميلك الأول",
      add_customer: "أضف عميل",
      filters: "التصفية",
      search_placeholder: "ابحث عن العميل بالاسم أو رقم الهاتف أو معرّف الطلب",
      show_only_subscribed: "اظهار العملاء المشتركين فقط",
      sort: "رتب حسب",
      total_orders: "مجموع الطلبات",
      table_headers: {
        name: "اسم العميل",
        orders_number: "عدد الطلبات",
        amount_spent: "إجمالي المبلغ المنفق",
        is_guest: "الحالة",
        subscribed_to_newsletter: "الاشتراك في النشرة البريدية",
      },
    },
    customers_list: {
      title: "عملاء",
    },
    add_customer: {
      basic_info: {
        header: "البيانات الأساسية",
        first_name: "الاسم الاول",
        last_name: "الاسم الاخير",
        email: "عنوان البريد الالكترونى",
        phone_number: "رقم الهاتف",
      },
      address_info: {
        header: "العنوان",
        area: "المنطقة",
        neighbourhood: "الحي",
        country: "البلد",
        address_line: "تفاصيل العنوان",
        building: "المبنى",
        floor: "الدور",
        apartment: "الشقة",
      },
    },
    customer_details: {
      customer_addresses: {
        title: "عناوين العميل",
        add_address: "أضف عنوان",
        add_btn: "أضف عنوان جديد",
        default_btn: "حدده كعنوان افتراضي",
        default: "افتراضي",
      },
      statistics: {
        title: "إحصاءات العملاء",
        customer_date: "عميل لـ {num} شهور",
        last_order: "آخر طلب",
        last_order_date: "{num} منذ أشهر",
        total_spent: "إجمالي الإنفاق حتى الآن",
        average_order_value: "متوسط ​​قيمة الطلب",
        active_orders: "عدد الطلبات النشطة",
        cancelled_orders: "عدد الطلبات الملغاة",
        draft_orders: "عدد الطلبات الغير مكتملة",
        gift_orders: "عدد الطلبات الهديه",
        total_orders: "عدد الطلبات الإجمالية",
      },
      last_order_details: {
        title: "آخر طلب",
        draft: "من الطلبات الغير مكتملة",
        active: "من الطلبات النشطة",
      },
    },
    filters: {
      amount_spent: "المبلغ المنفق",
      order_date: "تاريخ الطلب",
      date_placeholder: "حدد النطاق الزمني",
      customer_date: "تاريخ اضاف العميل",
      no_data: "لم يتم العثور على عملاء تطابق بحثك",
      modify_search: "حاول تعديل معايير البحث الخاصة بك ",
    },
  },
  settings: {
    general_settings: "الإعدادات العامة",
    admin_info_settings: "بيانات المسؤول",
    company_info_settings: "بيانات الشركة",
    checkout_settings: "اعدادات الدفع",
    regional_settings: "الإعدادات الإقليمية",
    company_address_settings: "عنوان الشركة",
    users_and_permissions: "المستخدمون والأذونات",
    billing: "الدفع",
    shipping: "الشحن والتسليم",
    integrations: "تكاملات",
    marketing: "تسويق",
    loyalty: "وفاء",
    payment: "بوابات الدفع",
    paymob: "Paymob",
    geidea: "Geidea",
    gccpay: "Gccpay",
    stripe: "Stripe",
    paytabs: "PayTabs",
    analytics: "التحليلات",
    policies: "سياسات",
    chat: "حديث",
    shop_customization: "تعديلات المتجر",
    social_media: "روابط وسائل التواصل الاجتماعي",
    advanced_settings: "إعدادات متقدمة",
    general_settings_tabs: {
      store_currency: {
        title: "عملة المتجر",
        currency_note: "يرجى التواصل مع الدعم لتغيير عملة متجرك",
      },
      paymob_receiving_currency: {
        title: "عملة الاستلام من Paymob",
        currency_note: "يرجى التواصل مع الدعم لتغيير عملة استلام متجرك",
      },
      price_decimals: "نقاط السعر العشرية",
    },
    integration_tabs: {
      geidea: {
        title: "Geidea",
        geideaApiPassword: "Api Password",
        geideaMerchantPublicKey: "Merchant Public Key",
      },
      gccpay: {
        title: "Gccpay",
        gccpayTerminalId: "Terminal Id",
        gccpayPassword: "Password",
        gccpayMerchantKey: "Merchant Key",
      },
      paymob_dashboard_btn: "اذهب للوحة تحكم Paymob",
      paymob_banner: {
        title: "إعداد Paymob",
        tag: "🌟 أكثر من 1200 تاجر يستخدمونه 🌟",
        desc: "استقبل المدفوعات عبر الإنترنت على الفور وانشأ تجارب شراء سلسة باستخدام طرق دفع آمنة متنوعة.",
        btn: "إعداد Paymob",
      },
      paymob: {
        title: "Paymob",
        paymobUsername: "اسم المستخدم",
        paymobHmacSecret: "كود hmac",
        paymobApiKey: "رقم API",
        paymobOnlineIntegrationId: "رقم تعريف الدمج الاونلاين",
        paymobCashIntegrationId: "رقم تعريف الدمج النقدي",
        paymobPaymentIframeId: "iframe رقم تعريف",
      },
      stripe: {
        title: "Stripe",
        stripeApiKey: "Stripe API Key",
        stripePublicKey: "Stripe Public Key",
        stripeEndpointSecret: "Stripe Endpoint Secret",
      },
      paytabs: {
        title: "PayTabs",
        paytabsServerKey: "PayTabs Server Key",
        paytabsProfileId: "PayTabs Profile ID",
      },
      valu: {
        title: "فاليو",
        paymobValuIntegrationId: "رقم تعريف فاليو",
        paymobValuIframeId: "رفم تعريف  iframe فاليو",
        valu_payment: "هل تريد استخدام دفع فاليو",
        useValuPayment: {
          true: "نعم",
          false: "لا",
        },
      },
      sympl: {
        title: "sympl",
        paymobSymplIframeId: "رقم تعريف iframe sympl",
        paymobSymplIntegrationId: "رقم تعريف sympl",
        symplStoreCode: "رمز متجر sympl ",
        symplStoreCodeHint: "اكتب رمز المتجر كـ STR-XXX",
        sympl_payment: "هل تريد استخدام دفع sympl",
        useSymplPayment: {
          true: "نعم",
          false: "لا",
        },
      },
      bank_installments: {
        title: "أقساط بنكية",
        paymobBankInstallmentsIntegrationId: "رقم تعريف دمج اقساط البنوك",
        paymobBankInstallmentsIframeId: "رقم تعريف iframe اقساط البنوك",
        bankInstallmentsPayment: "هل تريد استخدام اقساط البنوك",
        useBankInstallmentsPayment: {
          true: "نعم",
          false: "لا",
        },
      },
      mobileWallets: {
        title: "المحافظ الالكترونية",
        mobileWalletsPayment: "هل تريد استخدام دفع محافظ الالكترونية؟",
        paymobMobileWalletsIntegrationId: "رقم تعريف mobile wallets Integration",
        useMobileWalletsPayment: {
          true: "نعم",
          false: "لا",
        },
      },
      tabby: {
        title: "Tabby",
        tabbyPayment: "هل تريد استخدام دفع Tabby ؟",
        paymobTabbyIntegrationId: "Tabby Integration رقم تعريف",
        useTabbyPayment: {
          true: "نعم",
          false: "لا",
        },
      },
      souhoola: {
        title: "سهولة",
        souhoolaPayment: "هل تريد استخدام دفع سهولة ؟",
        paymobSouhoolaIntegrationId: "سهولة Integration رقم تعريف",
        useSouhoolaPayment: {
          true: "نعم",
          false: "لا",
        },
      },
      googleSiteVerification: {
        title: "google site verification",
        googleAnalyticsKey: "Google Analytics رقم تتبع",
        googleTagManagerKey: "Google Tag Manager رقم تعريف",
        googleSiteVerificationKey: "Google Site Verification رقم تعريف",
      },
      meta: {
        title: "meta",
        facebookPixelKey: "Facebook Pixel رقم تعريف",
        accessToken: "رمز الدخول إلى الفيسبوك",
        addMetaKey: "إضافة رقم تعريف Meta",
      },
      tiktokPixel: {
        title: "tiktok pixel",
        tiktokPixel: "Tiktok Pixel رقم تعريف",
        tiktokAccessToken: "Tiktok رمز الدخول",
      },
      facebookChat: {
        title: "دردشة الفيسبوك",
        facebookChatKey: "رقم تعريف صفحة فيسبوك",
      },
      mailChimp: {
        title: "mail chimp",
        mailchimpKey: "Mail Chimp API رقم",
      },
      gameball: {
        title: "gameball",
        gameballApiKey: "GameBall API رقم",
        gameballTransactionKey: "GameBall مفتاح الصفقة",
      },
    },
    info_tabs: {
      adminInfo: {
        title: "بيانات الادمن",
        name: "الاسم",
        email: "الايميل",
        phoneNumber: "رقم الهاتف",
      },
      companyInfo: {
        title: "بيانات الشركة",
        name: "منصة",
        url: "رابط المتجر",
        contactPhone: "رقم هاتف المتجر",
        defaultStoreLanguage: "لغة المتجر الاساسية",
        communicationLanguage: "لغة التواصل",
        referralCode: "كود الإحالة",
        postalCode: "رمز بريدي",
        contactEmails: "البريد الالكتروني للتواصل",
        emailHint: "اكتب البريد الإلكتروني ثم اضغط على Enter",
      },
      checkoutSettings: {
        title: "إعدادات الدفع",
        customization_btn: "تخصيص الآن",
        description_mandatory: "فيما يلي الحقول الإلزامية، يمكنك تغيير عنوان/مسميات الحقول",
        description_non_mandatory:
          " فيما يلي الحقول غير الإلزامية، يمكنك تغيير عنوان / تسميات الحقول، ,تغيير بعض الحقول وجعلها إلزامية وإخفاء الحقول غير المرغوب فيها.",
        mandatory_field: "حقل الزامي",
        non_mandatory_field: "حقل غير إلزامي",
        customization_fields: {
          first_name: "الاسم الأول",
          phone_number: "رقم الهاتف",
          country: "البلد",
          city: "المحافظة",
          region: "المنطقة",
          district: "الحي",
          address_line1: "تفاصيل العنوان",
          last_name: "اسم العائلة",
          email: "البريد الإلكتروني",
          building: "المبنى",
          floor: "الدور",
          apartment: "الشقة",
          postal_code: "الرمز البريدي",
          notes: "ملاحظات",
          required_field: "حقل الزامي",
          discard_field: "اظهار الحقل",
        },

        advanced_settings: {
          title: "إعدادات متقدمة",
        },
        abandoned_checkout_email: {
          title: "تذكير بالمنتجات المهجورة",
          description:
            "هل تريد إرسال تنبيهات آلية عبر البريد الإلكتروني إلى عملائك عندما يتركون المنتجات في عربة التسوق دون إتمام الشراء",
        },
        require_email_checkout: {
          title: "طلب البريد الإلكتروني لإتمام الشراء",
          description: "يعني تعطيل هذه الميزة أن عملائك لن يتلقوا رسائل تأكيدية عبر البريد الإلكتروني بعد إتمام الشراء",
        },
        gift_option: {
          title: "طلبات الهدايا",
          description: ".هل تريد السماح لعملائك بإرسال الهدايا للآخرين؟ يظهر كمفتاح تبديل عند الدفع.",
        },
        store_pickup: {
          title: "خيار الاستلام من المتجر",
          pickup_location: "اضف موقع الاستلام",
        },
        gift_option: {
          title: "إرسال كهدية",
          description: "هل تريد السماح لعملائك بإرسال المنتجات كهدايا للآخرين؟ سيظهر كخيار في صفحة إتمام الشراء",
        },
        pickup_subtitle: "يمكنك تفعيل هذه الميزة",
      },
      gift_option: {
        title: "طلبات الهدايا",
        description: ".هل تريد السماح لعملائك بإرسال الهدايا للآخرين؟ يظهر كمفتاح تبديل عند الدفع.",
      },
      companyAddress: {
        title: "عنوان الشركة",
      },
      regionalSettings: {
        title: "الإعدادات الإقليمية",
      },
      seo: {
        title: "SEO",
        description: "وصف",
        keywords: "الكلمات الدالة",
        hint: "اكتب الكلمة الأساسية ثم اضغط على Enter",
        statement:
          "يعمل تحسين محرك البحث (SEO) على زيادة جودة وكمية حركة مرور الموقع عن طريق زيادة وضوح موقع الويب أو صفحة الويب لمستخدمي محرك بحث الويب.",
      },
      socialMedia: {
        platform: "منصة",
        url: "URL",
      },
      companyLinks: {
        title: "منصات التواصل الاجتماعي",
        addPlatform: "إضافة منصة وسائط اجتماعية",
      },
      policies: {
        title: "سياسات",
        shippingEn: "أدخل سياسة الشحن الإنجليزية الخاصة بك أدناه",
        shippingAr: "أدخل سياسة الشحن العربية الخاصة بك أدناه",
        shippingHint: "مثال: سيتم شحن الطلبات في غضون 2-7 أيام عمل",
        checkoutHint: "مثال: أحسنت ، تم تقديم طلبك! شكرا للتسوق معنا.",
        checkoutMessage: "أدخل رسالة الخروج باللغة العربية أدناه",

        returnsRefundEn: "أدخل سياسة الإرجاع والاسترداد باللغة الإنجليزية الخاصة بك أدناه",
        returnsRefundAr: "أدخل سياسة الإرجاع والاسترداد باللغة العربية الخاصة بك أدناه",
      },
      shop_customization: {
        title: "تعديلات المتجر",
        price_template: {
          title: "صيغة السعر",
          desc: "(يطبق فقط على المنتجات ذات النطاق السعري)",
          toggle: "أضف صيغة سعر مخصصة",
          price_template_ar: "ادخل صيغة السعر بالعربية",
          price_template_en: "أضف صيغة السعر بالإنجليزية",
          select_template: "اختر إحدى الصيغ",
          note: "يجب أن تحتوي الصيغة على إحدى الكلمتين التاليتين أو كلاهما (min, max)",
        },
      },
    },
    shipping_tabs: {
      shipping_details: {
        title: "تفاصيل الشحن",
        courier: "اختر ساعي البريد الخاص بك",
        shipping_key: "مفتاح الشحن",
        courier_activation:
          "يرجى ملاحظة أنه من أجل تنشيط خدمات البريد السريع الخاصة بـ Zammit ، يجب توقيع عقد بين شركتك ومزود الخدمة Paymob. سوف نتصل بك لتسهيل العملية والبدء.",
        preferred_methods: "يرجى تحديد طرق الدفع المفضلة لديك عند اتمام الشراء",
        add_vat: {
          label: "اضافه ضريبه",
          title: "هل ترغب في إضافة 14٪ ضريبة القيمة المضافة إلى أسعارك عند الدفع؟",
          true: "نعم ، أضف 14٪ ضريبة القيمة المضافة إلى أسعاري",
          false: "لا ، أسعاري تشمل بالفعل ضريبة القيمة المضافة",
        },
        calculate_fees: {
          title: "هل تريد زامت أن تحسب رسوم الشحن عنك؟",
          at_cost: "نعم أريد أن يقوم زامت باحتساب رسوم الشحن لي",
          own_fees: "لا ، أريد إدخال رسوم الشحن الخاصة بي",
        },
        shipping_type: {
          title: "هل رسوم الشحن ثابتة أم تعتمد على الوزن؟",
          flat_fee: "رسم موحد",
          weight_based: "الوزن على أساس",
        },
        voo: {
          key: "البريد الإلكتروني لحساب VOO",
          hint: "أدخل بريدك الإلكتروني VOO Account هنا",
        },

        mylerz: {
          key: "بيانات حساب Mylerz",
          hint: "ادخل البيانات هكذا USERNAME:PASSWORD",
          business_category: "فئة العمل",
          warehouse_name_title: "اسم المستودع",
          warehouse_name_hint: "مثال: العاشر من رمضان المنطقه الصناعيه جنوب غرب ١٦ قطعه رقم ١٨٣",
          business_category_hint: "يمكنك التحقق من فئة عملك من Mylerz dashboard"
        },
        loadbugs: {
          key: "Bearer Token",
          hint: "افتح حساب Loadbugs الخاص بك ، واختر من القائمة الجانبية تكاملات> API> انسخ Bearer Token من الجزء الأوسط العلوي> الصقه أعلاه",
        },
        bosta: {
          key: "البريد الإلكتروني لحساب البوسطة: كلمة المرور",
          hint: "اكتب البريد الإلكتروني وكلمة المرور لبوستا بهذا التنسيق البريد الإلكتروني: كلمة المرور أدناه (يرجى ملاحظة أن كلمة مرورك يجب ألا تحتوي على الحرف #)",
        },
        khazenly: {
          sign_in: "قم بتسجيل الدخول باستخدام حساب Khazenly الخاص بك",
          success: "نجح ربط حسابك على Khazenly",
          error: "فشل ربط حسابك على Khazenly. حاول مرة اخرى.",
          khazenlyStoreName: "Store Name",
          khazenlyClientId: "Client ID",
          khazenlyClientSecret: "Client Secret",
          khazenlyAccountName: "Account Name",
        },
        eyedel: {
          key: "API Key",
          hint: "اضف ال API Key التي قمت بنسخها من Eyedel dashboard",
        },
        shipping_fees: "مصاريف الشحن",
        extra_kilo_fees: "رسوم الكيلو الاضافي",
        useCardCollection: "بطاقة عند التسليم",
        useCashCollection: "الدفع عند الاستلام",
        useOnlinePayment: "الدفع الالكتروني Paymob",
        useGeideaPayment: "الدفع الالكتروني Geidea",
        useGccpayPayment: "الدفع الالكتروني Gccpay",
        useStripePayment: "الدفع الالكتروني Stripe",
        usePaytabsPayment: "الدفع الالكتروني PayTabs",
        useBankTransfer: "تحويل بنكي",
      },
      cart: {
        title: "عربة التسوق",
        minimum_order: "أدخل الحد الأدنى لمبلغ الطلب",
        minimum_shipping: "أدخل الحد الأدنى لمبلغ الطلب للشحن المجاني",
        cod_fee: "أدخل رسوم الدفع عند الاستلام",
      },
      areas: {
        title: "مناطق الشحن والاستثناءات",
        available_cities: "المدن المتاحة",
        remove_address: "إزالة العنوان",
        exceptions: {
          title: "استثناءات الشحن",
          add_exceptions: "أضف استثناءات",
          shipping_fees: "مصاريف الشحن",
          additional_kilo_fees: "رسوم الكيلو الاضافيه",
          hint: "لا تحدد منطقة إذا كنت تريد تطبيق الاستثناء على جميع المناطق في هذه المدينة",
        },
        disabled_regions: {
          title: "مناطق مغلقه",
          add_disabled_regions: "اضف مناطق مغلقه",
        },
      },
      own_locations: {
        title: "مواقع الشحن",
        available_locations: "المواقع المتاحة للشحن",
        disabled_regions: "المناطق المعطلة",
        table_headers: {
          country: "البلد",
          city: "المحافظة",
          region: "المنطقة",
          district: "الحي",
          custom_fee: "مصاريف الشحن",
          extra_kilo_price: "رسوم الكيلو الاضافيه",
          action: "إجراء",
        },
        add_more: "أضف المزيد",
        add_location: "أضف موقعًا جديدًا",
        edit_location: "تعديل الموقع",
        add_disabled_region: "أضف منطقة معطلة",
        apply_on_all_cities: "تطبيق على جميع المدن",
        apply_on_all_regions: "تطبيق على جميع المناطق",
        apply_on_all_districts: "تطبيق على جميع الأحياء",
        edit: "تعديل",
        delete: "حذف",
        save: "حفظ",
        cancel: "إلغاء",
      },
    },
    errors: {
      shipping: {
        missing_key: "الرجاء إدخال مفتاح الشحن",
        missing_payment: "الرجاء تحديد طريقة دفع واحدة على الأقل",
        missing_fee: "الرجاء إدخال رسوم الشحن",
      },
    },
    upgrade: "ترقية",
    plan: "خطتك لاستخدام {name} الآن",
    success_message: "تم تحديث إعدادات الشركة بنجاح",
    user_success_message: "تحديث إعدادات المستخدم بنجاح",
  },

  partners: partner,
};
