import Vuex from "vuex";

// used instead vuex method to enable reset state
import { createStore } from "vuex-extensions";
import createPersistedState from "vuex-persistedstate";
import { store } from "quasar/wrappers";

import auth from "./auth";
import products from "./products";
import onboarding from "./onboarding";
import collections from "./collections";
import orders from "./orders";
import plans from "./plans";
import discounts from "./discounts";
import analytics from "./analytics";
import navigation from "./navigation";
import variants from "./variants";
import customers from "./customers";
import settings from "./settings";
import zammitUpdates from "./zammitUpdates";
import zammitPartners from "./zammitPartners";
import reviews from "./reviews";

const Store = createStore(Vuex.Store, {
  modules: {
    auth,
    products,
    customers,
    onboarding,
    collections,
    orders,
    plans,
    discounts,
    analytics,
    navigation,
    variants,
    customers,
    settings,
    zammitUpdates,
    zammitPartners,
    reviews,
  },

  plugins: [
    createPersistedState({
      paths: ["auth"],
    }),
  ],

  // enable strict mode (adds overhead!)
  // for dev mode and --debug builds only
  strict: process.env.DEBUGGING,
});

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(function ({ ssrContext }) {
  return Store;
});

export { Store };
