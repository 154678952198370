import ReviewAPI from "src/api/Review";

export async function updateReviewStatus({ commit }, { review, productId }) {
  try {
    const response = await ReviewAPI.updateReviewStatus(review, productId);
    commit("updateReviewStatus", response);
    return response;
  } finally {
  }
}

export async function updateAllReviewsStatus({ commit }, { status, productId }) {
  try {
    const response = await ReviewAPI.updateAllReviewsStatus(status, productId);
    commit("updateAllReviewsStatus", status);
    return response;
  } finally {
  }
}

export async function getReviews({ commit }, { productId, pagination }) {
  commit("updateReviewsLoading", true);
  try {
    const response = await ReviewAPI.getReviews(productId, pagination);
    commit("updateReviewsList", response);
    return response;
  } finally {
    commit("updateReviewsLoading", false);
  }
}
